import "../../src/assets/css/style.css";
import Pageorderstracking from "../components/layouts/Pageorderstracking";
import Pageproductslist from "../components/layouts/Pageproductslist";
import Pagereviews from "../components/layouts/Pagereviews";
import Pagesellerdetail from "../components/layouts/Pagesellerdetail";
import Pagesellerslist from "../components/layouts/Pagesellerslist";
import Pagesettings from "../components/layouts/Pagesettings";
import Pagetransactions from "../components/layouts/Pagetransactions";
import Pagetransactiondetails from "../components/layouts/Pagetransactiondetails";
import Pageaccountlogin from "../components/layouts/Pageaccountlogin";
import Pageaccountregister from "../components/layouts/Pageaccountregister";
import Pageblank from "../components/layouts/Pageblank";
import Pagebrands from "../components/layouts/Pagebrands";
import Pagecategories from "../components/layouts/Pagecategories";
import Pageerror from "../components/layouts/Pageerror";
import Pageformproduct from "../components/layouts/Pageformproduct";
import Pageformprofit from "../components/layouts/Pageformprofit";
import Pageinvoice from "../components/layouts/Pageinvoice";
import Pageorders from "../components/layouts/Pageorders";

import Pageordersdetail from "../components/layouts/Pageordersdetail";
import Login from "../components/Login";
import InventoryPage from "../components/layouts/InventoryPage";
import VendorsPage from "../components/layouts/VendorsPage";
import PurchaseCompanyList from '../components/layouts/PurchaseCompanyList'
import PurchaseInvoice from '../components/layouts/PurchaseInvoice'
import AddPruchaseCompany from "../components/layouts/AddPurchaseCompany";
import AddPurchaseInvoice from "../components/layouts/AddPurchaseInvoice";
import { ReminderPage } from "../components/layouts/ReminderPage";
import ReportOrder from "../components/layouts/ReportOrder";
import PriceCategory from "../components/layouts/PriceCategory";
import ReportInventory from "../components/layouts/ReportInventory";
import ReportPurchaseList from "../components/layouts/ReportPurchaseList";
import Support from "../components/layouts/Support";
import ProfitMargin from "../components/layouts/ProfitMargin";
import CreateOrder from "../components/Order/CreateOrder";
import OrderPaymentList from "../components/Order/OrderPaymentList";
import AddPayment from "../components/Order/AddPayment";
import DashboardOrder from "../components/Dashboard/DashBoardOrder";

import { OrderSalesReminder } from "../components/Reminder/OrderSalesReminder";
import { SalesDropReminder } from "../components/Reminder/SalesDropReminder";
import { WeeklyPayReminder } from "../components/Reminder/WeeklyPayReminder.";
import Home from "../components/Dashboard/Home";
import DashboardPurchase from "../components/Dashboard/DashBoardPurchase";
import DashboardPayment from "../components/Dashboard/DashBoardPayment";

// import DashboardPayment from "../components/layouts/DashBoardPayment";
import PurchasePaymentList from "../components/Purchase/PurchasePaymentList";
import PurchasePaymentAdd from "../components/Purchase/PurchasePaymentAdd";
import PageUsers from "../components/layouts/PageUsers";
import PageUserAdd from "../components/layouts/PageUsersAdd";
import PageUsersAdd from "../components/layouts/PageUsersAdd";
import { PurchaseReminder } from "../components/Reminder/PurchaseReminder";
import PageCotation from "../components/layouts/PageCotation";
import PageQuotationdetails from "../components/layouts/PageQuotationdetails";
const authProtectedRoutes = [
  { path: "/", component: Home },
  { path: "/pageordersdetail", component: Pageordersdetail },
  { path: "/pageordersdetail/:id", component: Pageordersdetail },
  { path: "/pageorderstracking", component: Pageorderstracking },
  { path: "/pageproductslist", component: Pageproductslist },
  { path: "/pagereviews", component: Pagereviews },
  { path: "/pagesellerdetail", component: Pagesellerdetail },
  { path: "/pagesellerslist", component: Pagesellerslist },
  { path: "/pagesettings", component: Pagesettings },
  { path: "/pagetransactions", component: Pagetransactions },
  { path: "/pagetransactiondetails", component: Pagetransactiondetails },
  { path: "/pageblank", component: Pageblank },
  { path: "/pagebrands", component: Pagebrands },
  { path: "/pagecategories", component: Pagecategories },
  { path: "/pageerror", component: Pageerror },
  { path: "/product", component: Pageformproduct },
  { path: "/product/add", component: Pageformproduct },
  { path: "/product/edit/:id", component: Pageformproduct },
  { path: "/pageformprofit", component: Pageformprofit },
  { path: "/pageinvoice", component: Pageinvoice },
  { path: "/pageorders", component: Pageorders },
  { path: "/inventory", component: InventoryPage },
  { path: "/vendor", component: VendorsPage },
  { path: "/vendor/add", component: VendorsPage },
  { path: "/vendor/edit/:id", component: VendorsPage },
  { path: "/reminder/ordersales", component: OrderSalesReminder },
  { path: "/reminder/salesdrop", component: SalesDropReminder },
  { path: "/reminder/weelypay", component: WeeklyPayReminder },
  { path: "/reminder/purchase", component: PurchaseReminder },
  { path: "/purchanseCompanyList", component: PurchaseCompanyList },
  { path: "/purchanseInovice", component: PurchaseInvoice },
  { path: "/purchanseCompanyList/add", component: AddPruchaseCompany },
  { path: "/purchanseCompanyList/edit/:id", component: AddPruchaseCompany },
  { path: "/purchanseInovice/add", component:AddPurchaseInvoice  },
  { path: "/purchanseInovice/edit/:id", component:AddPurchaseInvoice  },
  { path: "/pricecategory", component:PriceCategory  },
  { path: "/profitmargin", component:ProfitMargin  },
  { path: "/order/report", component:ReportOrder  },
  { path: "/inventory/report", component: ReportInventory },
  { path: "/pusrchase/report", component: ReportPurchaseList },
  { path: "/support", component: Support },
  { path: "/pageorders/add", component: CreateOrder },
  { path: "/pageorders/add/:id", component: CreateOrder },
  { path: "/payment/orderpaymentlist", component: OrderPaymentList },
  { path: "/payment/orderpaymentlist/add", component: AddPayment },
  { path: "/dashboardOrder", component: DashboardOrder },
  { path: "/dashboardPurchase", component: DashboardPurchase },
  { path: "/dashboardPayment", component: DashboardPayment },
  { path: "/payment/purchasepaymentlist", component: PurchasePaymentList },
  { path: "/payment/purchasepaymentlist/add", component: PurchasePaymentAdd },
  { path: "/pageusers", component: PageUsers },
  { path: "/pageusers/add", component: PageUsersAdd },
  { path: "/pageusers/edit/:id", component: PageUsersAdd },
  { path: "/pagecotation", component: PageCotation },
  { path: "/pagecotationdetails/:id", component: PageQuotationdetails },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/pageaccountregister", component: Pageaccountregister },
];

export { authProtectedRoutes, publicRoutes };
