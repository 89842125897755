export const purchaseCompanyActionTypes={

    GETPURCHASECOMPANYDATA_REQUEST: "@@purchasecompany/GETPURCHASECOMPANYDATA_REQUEST",
    GETPURCHASECOMPANYDATA_SUCCESS: "@@purchasecompany/GETPURCHASECOMPANYDATA_SUCCESS",
    GETPURCHASECOMPANYDATA_FAILURE: "@@purchasecompany/GETPURCHASECOMPANYDATA_FAILURE",
  
    POSTPURCHASECOMPANYDATA_REQUEST: "@@purchasecompany/POSTPURCHASECOMPANYDATA_REQUEST",
    POSTPURCHASECOMPANYDATA_SUCCESS: "@@purchasecompany/POSTPURCHASECOMPANYDATA_SUCCESS",
    POSTPURCHASECOMPANYDATA_FAILURE: "@@purchasecompany/POSTPURCHASECOMPANYDATA_FAILURE",

    UPDATEPURCHASECOMPANYDATA_REQUEST: "@@purchasecompany/UPDATEPURCHASECOMPANYDATA_REQUEST",
    UPDATEPURCHASECOMPANYDATA_SUCCESS: "@@purchasecompany/UPDATEPURCHASECOMPANYDATA_SUCCESS",
    UPDATEPURCHASECOMPANYDATA_FAILURE: "@@purchasecompany/UPDATEPURCHASECOMPANYDATA_FAILURE",


    DELETEPURCHASECOMPANYDATA_REQUEST: "@@purchasecompany/DELETEPURCHASECOMPANYDATA_REQUEST",
    DELETEPURCHASECOMPANYDATA_SUCCESS: "@@purchasecompany/DELETEPURCHASECOMPANYDATA_SUCCESS",
    DELETEPURCHASECOMPANYDATA_FAILURE: "@@purchasecompany/DELETEPURCHASECOMPANYDATA_FAILURE",

    GETPURCHASEREMINDERDATA_REQUEST: "@@purchasecompany/GETPURCHASEREMINDERDATA_REQUEST",
    GETPURCHASEREMINDERDATA_SUCCESS: "@@purchasecompany/GETPURCHASEREMINDERDATA_SUCCESS",
    GETPURCHASEREMINDERDATA_FAILURE: "@@purchasecompany/GETPURCHASEREMINDERDATA_FAILURE",

    PCSTATERESET_REQUEST: "@@purchasecompany/PCSTATERESET_REQUEST",

}
export const purchaseCompanyInitialState={
    Loading:false,
    companyData:[],
    postPCDataLoad:false,
    postPCDataSuccess:false,
    updatePCdataLoad:false,
    updatePCdataSuccess:false,
    deletePCdataLoad:false,
    deletePCDataSuccess:false,
    purchseReminderLoading:false,
    purchaseReminderData:[]

}
export const companySelector=(state)=> state.PurchaseCustomerReducer;