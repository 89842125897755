import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const postBrandApi = (formData) => {
  console.log(formData,'dhgjkhzsk');
  
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/brand/create", formData, Http.getFileAuthToken());
};
export const getBrandApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/brand?query=" +
      payload.query +
      "&page=" +
      payload.page +
      "&limit=" +
      payload.limit, Http.getAuthToken());
};

export const updateBrandergoryApi = ({ formData, id }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/brand/update/" + id,
    formData,
    Http.getFileAuthToken()
  );
};

export const deleteBrandergoryApi = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/brand/delete/" + id, Http.getAuthToken());
};
