/* External Imports */
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

/* Internal Imports */
import { signinValidator } from "../validators/Validators";
import {
  authSelector,
  clearAuthMessage,
  loginUserRequest,
} from "../store/auth/auth.action";
import logo from "../assets/imgs/sikan-logo.png";
import { useEffect } from "react";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signinValidator),
  });
  const { auth, error,screen } = useSelector(authSelector);
  console.log(auth, "6789");
  console.log(screen, "6789");
  const handleSignin = (formData) => {
    dispatch(loginUserRequest(formData));
  };

  useEffect(() => {
    if (auth.roleId == 2||auth.roleId == 1) {
      navigate("/");
      dispatch(clearAuthMessage());
    }
  }, [auth]);
  useEffect(() => {
    dispatch(clearAuthMessage());
  }, []);
  return (
    <>
      <main className="">
        <div style={{ textAlign: "center", marginTop: "5%" }}>
          <img
            className="logo"
            style={{ height: "59px" }}
            src={logo}
            alt="Evara Dashboard"
          />
        </div>
        <section className="content-main">
          <div className="card mx-auto card-login">
            <div className="card-body">
              <h4 className="card-title mb-4">Sign in</h4>
              <form onSubmit={handleSubmit(handleSignin)}>
                <div className="mb-3">
                  <input
                    className="form-control"
                    placeholder="Username or email"
                    type="text"
                    {...register("email")}
                  />
                  {errors?.email?.message && (
                    <p className="error text-danger">{errors.email.message}</p>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    className="form-control"
                    placeholder="Password"
                    type="password"
                    {...register("password")}
                  />
                  {errors?.password?.message && (
                    <p className="error text-danger">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                {error &&
                  (error === "Not Found" ? (
                    <p className="error text-danger text-center">
                      It looks like this email isn’t registered. Double-check
                      and try again.
                    </p>
                  ) : (
                    <p className="error text-danger text-center">
                      The password you entered is incorrect. Please try again.
                    </p>
                  ))}

                <div className="mb-3">
                  <a className="float-end font-sm text-muted" href="#">
                    Forgot password?
                  </a>
                  <label className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked=""
                    />
                    <span className="form-check-label">Remember</span>
                  </label>
                </div>
                <div className="mb-4">
                  <button className="btn btn-primary w-100" type="submit">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Login;
