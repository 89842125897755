import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  dueDayDataRequest,
  getCardDataRequest,
  getOrderCardDataRequest,
} from "../../store/dashboard/dashboard.action";
import { dashboardSelector } from "../../store/dashboard/dashboard.model";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { orderSelector } from "../../store/order/order.model";
import {
  creditDueDataRequest,
  getUserOrderDataRequest,
  UpdateOrderStatusRequest,
} from "../../store/order/order.action";
import { createOrderPayload } from "../../utils/config";
import { statusClasses, statusData } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
function DashboardOrder() {
  // Selector
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userOrderData } = useSelector(orderSelector);
  const {  ordercardData } = useSelector(dashboardSelector);
  const { creditDueData } = useSelector(orderSelector);

  console.log(userOrderData, "6789");
  console.log(creditDueData, "creditDueData");
  console.log(ordercardData, "ordercardData");

  //   state
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
 
  const [searchQuery, setSearchQuery] = useState("");
 

  /* Pagenation*/
  const totalPages1 = Math.ceil(userOrderData.count / rowsPerPage);

  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1 && currentPage > 3
        ? currentPage + 2
        : null
      : null;
  const lastPage_before =
    currentPage > 3 && totalPages1 != currentPage ? "..." : null;
  const lastPage =
    currentPage > 3 && totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);

  //   function
  const onStatusChanges = (eve, id) => {
    console.log(eve, id, "evvid");
    const payload = {
      id,
      status: eve,
    };
    dispatch(UpdateOrderStatusRequest(payload));
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  /* useEffect */
  useEffect(() => {}, []);
  useEffect(() => {
    dispatch(getOrderCardDataRequest());
  }, []);
  useEffect(() => {
    const today1 = new Date();
    const date = dayjs(today1).format("YYYY-MM-DD");
    const today = dayjs();
    const startOfDay = today.startOf("day").format("YYYY-MM-DD");

    const endOfDay = today.endOf("day").format("YYYY-MM-DD");
    const payload = {
      // query: searchQuery,
      // page: page,
      // limit: rowsPerPage,
      // from_date: date,
      // to_date: date,

      page: page,
      limit: rowsPerPage,
      query: searchQuery,
      status: "",
      customer: "",
      order_status: "",
      order_id: "",
      from_date: startOfDay,
      to_date: endOfDay,
    };
    dispatch(getUserOrderDataRequest(payload));
  }, [searchQuery, rowsPerPage, currentPage]);
 

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Order Dashboard</h2>
              <p>Order data about your business here</p>
            </div>
            {/* <div>
              <a className="btn btn-primary" href="#">
                <i className="text-muted material-icons md-post_add"></i>Create
                report
              </a>
            </div> */}
          </div>
          <Row>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Total</h6>
                    <span>{ordercardData?.totalOrders}</span>
                    {/* <span className="text-sm">
                      Increase by 15% from Last year
                    </span> */}
                    {/* <span className="text-sm">
                      Shipping fees are not included
                    </span> */}
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    {/* <i className="text-success material-icons md-local_shipping"></i> */}
                    <i className="text-warning material-icons md-monetization_on"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Total</h6>
                    <span>
                      ₹{parseFloat(ordercardData?.monthlyRevenue?.monthlyRevenue).toFixed(2)}
                    </span>
                    {/* <span className="text-sm">Excluding orders in transit</span> */}
                    {/* <span className="text-sm">
                      Increase by 15% from Last year
                    </span> */}
                  </div>
                </article>
              </div>
            </Col>

            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Received Today</h6>
                    {/* <span>{ordercardData?.today_orders}</span> */}
                    <span>{ordercardData?.todayOrders}</span>
                    {/* <span className="text-sm">
                      Increase by 15% from yesterday
                    </span> */}
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Received Today</h6>
                    <span>
                      ₹{parseFloat(ordercardData?.todayRevenue?.todayRevenue??0).toFixed(2)}
                    </span>
                    {/* <span className="text-sm">
                      Increase by 15% from yesterday
                    </span> */}
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <div className="card">
            <div className="card-header">
              <h4>Order List</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Customer name</th>
                      <th>Price</th>
                      <th>Status</th>
                      {/* <th>Manage Status</th> */}
                      <th className="text-start">Payment Status</th>

                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userOrderData?.data?.map((order) => (
                      <tr key={order?.orders?.id}>
                        <td>{order?.id}</td>

                        <td>
                          <b>{order?.user?.name}</b>
                        </td>

                        <td>₹{parseFloat(order?.sub_total).toFixed(2)}</td>
                        <td>
                          <span
                            className={`badge rounded-pill ${
                              statusClasses[order?.status] || "alert-secondary"
                            }`}
                          >
                            {statusData[order?.status]}
                          </span>
                        </td>
                        {/* <td>
                          <select
                            name=""
                            className="form-select w-75"
                            onClick={(e) =>
                              onStatusChanges(e.target.value, order?.id)
                            }
                          >
                            <option className="option" value={""}>
                              SELECT
                            </option>
                            <option className="option" value={0}>
                              ORDER INITIATED
                            </option>
                            <option className="option" value={1}>
                              ONHOLD
                            </option>
                            <option className="option" value={2}>
                              DISPATCHED
                            </option>
                            <option className="option" value={3}>
                              DELIVERY IN PROCESS
                            </option>
                            <option className="option" value={4}>
                              DELIVERED
                            </option>
                            <option className="option" value={5}>
                              COMPLETED
                            </option>
                            <option className="option" value={6}>
                              PAID
                            </option>
                          </select>
                        </td> */}

                      
                        <td>
                          {order?.payment_status == 0 ? "Unpaid" : "Paid"}
                        </td>
                        <td>{dayjs(order?.created_at).format("DD.MM.YYYY")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-15 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {pagesToDisplay.map((page) => {
                  return page != "..." ? (
                    <li
                      key={page}
                      className={`page-item b ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ) : page == "..." ? (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ) : (
                    ""
                  );
                })}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default DashboardOrder;
