import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { productSelector } from "../../store/product/product.model";
import { useParams } from "react-router-dom";
import { NodeServer } from "../../services/config";
import { API_BASE } from "../../services/config";
import { ProductAddListValiator } from "../../validators/Validators";
import {
  getProductPriceRequest,
  getProductRequest,
  postProductRequest,
  productStateResetRequest,
  UpdateProductRequest,
} from "../../store/product/product.action";
import { getCatRequest } from "../../store/category/category.action";
import { CatAddSelector } from "../../store/category/category.model";
import { getBrandRequest } from "../../store/brand/brand.action";
import { BrandSelector } from "../../store/brand/brand.model";
import {
  Category,
  pricePercentage,
  selectDefault,
  TaxPercentage,
} from "../../utils/Constants";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-dropdown-select";

import { SlideshowLightbox } from "lightbox.js-react";
function Pageformproduct() {
  const id = useParams();
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("id");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(ProductAddListValiator),
  });
  console.log(errors, "eireerei");
  /* Selectors */
  const { getCatData } = useSelector(CatAddSelector);
  const { getBrandData } = useSelector(BrandSelector);
  const { productSendSuccess, productUpdateSuccess, getProductData, cat } =
    useSelector(productSelector);
  console.log(cat, "ccssc");
  console.log(getProductData, "sldfsf8n");
  /* State */
  const [image, setImage] = useState([]);
  const [opt, setOpt] = useState();
  const [imagePreview, setImagePreview] = useState([]);
  const [imgAdd, setImgAdd] = useState();
  // const [imgAdd, setImgAdd] = useState(false);
  const [ids, setId] = useState(0);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [catSel, setCatSel] = useState(null);

  const removeImage = (id) =>{
    //images are inserted correctly cannot delete 
    setImagePreview((oldState)=>oldState.filter((item,index)=> index !== id))
    // images.map((im)=>console.log(im.id)) //images are deleted from list not from screen 
  }
  const onSubmitHandler = (formData) => {
    delete formData.category_id;
    delete formData.cat;
    // delete formData.msrp;

    if (!formData.tax) {
      formData.tax = "";
    }
    
    // formData.image = image
    // if(image.length >0){
    //   formData.image = image
    // }
    formData.image = imagePreview
    formData.user_id = user_id;
    formData.unit_type = 0;
    formData.featured = "null";

    console.log(formData, image,"hjjak");
    // return
    if (ids === 0 || ids == undefined) {
      dispatch(postProductRequest(formData));
    } else {
      formData.id = ids;
      dispatch(UpdateProductRequest(formData));
    }
  };

  const PriceChange = (value) => {
    console.log(value, "1233");
    if (value > 0) {
      const catData = cat.find((ele) => {
        if (catSel != null) {
          if (ele.catgory == catSel?.catgory) {
            return ele;
          }
        }
      });
      console.log(catData, "kfhgkdhk", catSel);
      if (catData) {
        const retial = (
          Number(value) +
          (Number(catData.retial) / 100) * Number(value)
        ).toFixed(2);
        const whole = (
          Number(value) +
          (Number(catData.wholeSale) / 100) * Number(value)
        ).toFixed(2);
        const credit = (
          Number(value) +
          (Number(catData.credit) / 100) * Number(value)
        ).toFixed(2);
        console.log(retial, whole, credit, "1233");
        setValue("c_rate", credit);
        setValue("w_rate", whole);
        setValue("msrp", retial);
      }
    } else {
      setValue("c_rate", "");
      setValue("w_rate", "");
      setValue("msrp", "");
    }
  };
  const taxChange = (tax) => {
    console.log(tax, "uytoiytpe");
  };

  const catChange = (selectedOption) => {
    setCatSel(selectedOption);
    setValue("cat",selectedOption[0]?.value)
    console.log(selectedOption[0]?.value, "selectedOption");
    let p_rate = getValues("p_rate");
    console.log(p_rate, "jjj");
    if (p_rate > 0) {
      const catData = cat.find((ele) => {
        console.log(ele, "opopopoop");

        if (ele.category == selectedOption[0]?.value) {
          return ele;
        }
      });
      console.log(p_rate, catData, selectedOption[0]?.value, "opopopoop");

      const retial = (
        Number(p_rate) +
        (Number(catData?.retial) / 100) * Number(p_rate)
      ).toFixed(2);
      const whole = (
        Number(p_rate) +
        (Number(catData?.wholeSale) / 100) * Number(p_rate)
      ).toFixed(2);
      const credit = (
        Number(p_rate) +
        (Number(catData?.credit) / 100) * Number(p_rate)
      ).toFixed(2);
      console.log(retial, whole, credit, "1233");
      setValue("c_rate", credit);
      setValue("w_rate", whole);
      setValue("msrp", retial);
    } else {
      setValue("c_rate", "");
      setValue("w_rate", "");
      setValue("msrp", "");
    }
    if (selectedOption && selectedOption.length > 0) {
      setValue("price_category_id", selectedOption[0]?.value);
    } else {
      setValue("price_category_id", null);
    }
  };
  useEffect(() => {
    const payload = {
      query: "",
      page: "",
      limit: "",
    };
    dispatch(getCatRequest(payload));
    dispatch(getBrandRequest(payload));
    dispatch(productStateResetRequest());
  }, []);

  const handleImageChange = (e) => {
    console.log(imagePreview, "dfskh");
  
    const file =[...e.target.files];
    if (file) {
      const newimg = [...image,file];
      console.log('usduiyad7');
      
      let newimgprev = []
      imagePreview ===null? (newimgprev = [file]): (newimgprev = [...imagePreview,file])
      // newimg.push(file);
      setImage(newimg);
      console.log(image.length, "usduiyad7");

      
       setImagePreview(newimgprev);
      // setImgAdd(file)
      console.log(newimgprev,'newimgprev');
    
    }
  };

  useEffect(() => {
    if (id && id?.id) {
      setId(id?.id);
    }
  }, [id]);

  useEffect(() => {
    if (ids !== 0) {
      const payload = {
        query: "",
        prod_id: ids,
        page: 0,
        limit: 0,
      };
      dispatch(getProductRequest(payload));
    }
  }, [ids]);

  useEffect(() => {
    if (getProductData?.count > 0) {
      getProductData?.data?.map((ele) => {
        console.log(ele, "plm");

        // setImagePreview(
        //   ele?.image?.includes("/products/")
        //     ? NodeServer.baseURL + ele?.image
        //     : NodeServer.baseURL + "/products" + ele?.image
        // );
      
        setImagePreview(ele?.image);
        // setImage(ele?.image);
        
        
        if (ele?.price_category_id) {
          let catOpt = pricePercentage?.find((p) => {
            if (p?.id == ele?.price_category_id) {
              return p;
            }
          });
          // console.log(catOpt,"plmss");
          setCatSel([catOpt]);
        }

        reset({
          name: ele?.name,
          description: ele?.description,
          featured: ele?.featured,
          unit_type: ele?.unit_type,
          p_rate: ele?.p_rate,
          c_rate: ele?.c_rate,
          msrp: ele?.msrp,
          msrp: ele?.msrp,
          w_rate: ele?.w_rate,
          rack: ele?.rack,
          hsn_code: ele?.hsn_code,
        });
        let brandData = getBrandData?.data?.filter(
          (ele1) => ele1?.id == ele?.brand_id
        );
        setValue("brand_id", brandData[0]?.id);
        let catData = getCatData?.data?.filter(
          (ele2) => ele2?.id == ele?.catgory_id
        );
        setValue("catgory_id", catData[0]?.id);
        setValue("cat", catData[0]?.id);
        let taxData = TaxPercentage?.filter((tax) => tax.value == ele?.tax);
        setSelectedOption2(taxData);
        setValue("tax", taxData[0]?.value);
        console.log( taxData[0]?.value, "isodfsfj");
      });
    }
  }, [getProductData]);
  useEffect(() => {
    dispatch(getProductPriceRequest());
  }, [dispatch]);

  useEffect(() => {
    if (productSendSuccess || productUpdateSuccess) {
      navigate("/pageproductslist");
      dispatch(productStateResetRequest());
      reset();
      setId(0);
      setImagePreview(null);
      setImage(null);
      if (imageRef.current) {
        imageRef.current.value = "";
      }
    }
  }, [productSendSuccess, productUpdateSuccess]);
  // useEffect(() => {
  //   setImagePreview(imagePreview); 
  //   console.log("shfsh");
    
  // }, [imagePreview]);
  console.log(imagePreview,image, "opop");
  // uploads\\products

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <form
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Row>
              <Col md={12}>
                <div className="content-header">
                  <h2 className="content-title">
                    {ids === 0 || ids == undefined
                      ? "Add New Product"
                      : "Edit Product"}
                  </h2>
                  <div>
                    {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">
                      Save to draft
                    </button> */}
                    <button
                      className="btn btn-md rounded font-sm hover-up"
                      type="submit"
                    >
                      Save Product
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={8} className="pb-85">
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Product Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label required" htmlFor="product_name">
                            Product Title
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            {...register("name")}
                            placeholder="Type here"
                          />
                          {errors?.name && (
                            <p className="text-danger">
                              {errors?.name?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label required">Description</label>
                          <textarea
                            className="form-control"
                            {...register("description")}
                            placeholder="Type here"
                            rows="4"
                          ></textarea>
                          {errors?.description && (
                            <p className="text-danger">
                              {errors?.description?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-2 overflow-visible ">
                  <div className="card-header">
                    <h4>Price & Tax Information</h4>
                  </div>
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label required">Tax %</label>
                          <Select
                            placeholder="Tax Percent"
                            className="form-control"
                            options={TaxPercentage || []}
                            disabled={false}
                            dropdownHandle={true}
                            searchable={true}
                            searchBy="name"
                            labelField={"name"}
                            valueField={"value"}
                            values={selectedOption2 || []}
                            name="tax"
                            onChange={(selectedOption) => {
                              setValue("tax", selectedOption[0]?.value);
                              setSelectedOption2(selectedOption);
                              taxChange(selectedOption[0]?.value);
                            }}
                          />
                          {errors?.tax && (
                            <p className="text-danger">
                              {errors?.tax?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="mb-4">
                          <label className="form-label required">Price Catagery</label>
                          <Select
                            placeholder="Price Category"
                            className="form-control"
                            options={pricePercentage || []}
                            disabled={false}
                            dropdownHandle={true}
                            searchable={true}
                            searchBy="price_category_id"
                            labelField={"name"}
                            valueField={"value"}
                            name="pcatg"
                            //   values={
                            //     cat?.filter(
                            //     (ele) =>
                            //       ele.id === getValues("price_category_id") ||
                            //       ele.id ===
                            //         (getProductData?.data?.[0]
                            //           ?.price_category_id || null)
                            //   )
                            // }
                            values={catSel || []}
                            onChange={(value) => catChange(value)}
                          />
                             {errors?.cat && (
                            <p className="text-danger">
                              {errors?.cat?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">HSN Code</label>
                          <input
                            className="form-control"
                            {...register("hsn_code")}
                            placeholder="HSN Code"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label required">P. Price</label>
                          <input
                            className="form-control"
                            {...register("p_rate")}
                            onChange={(e) => PriceChange(e.target.value)}
                            placeholder="P. Price"
                            type="text"
                          />
                          {errors?.p_rate && (
                            <p className="text-danger">
                              {errors?.p_rate?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label required">W. Price</label>
                          <input
                            className="form-control"
                            {...register("w_rate")}
                            placeholder="W. Price"
                            type="text"
                          />
                          {errors?.w_rate && (
                            <p className="text-danger">
                              {errors?.w_rate?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label required">C. Price</label>
                          <input
                            className="form-control"
                            {...register("c_rate")}
                            placeholder="C. Price"
                            type="text"
                          />
                          {errors?.c_rate && (
                            <p className="text-danger">
                              {errors?.c_rate?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Retail Price</label>
                          <input
                            className="form-control"
                            {...register("msrp")}
                            placeholder="Retial Price"
                            type="text"
                          />
                          {errors?.msrp && (
                            <p className="text-danger">
                              {errors?.msrp?.message}
                            </p>
                          )}
                        </div>
                      </div> */}
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label required required">MSRP</label>
                          <input
                            className="form-control "
                            {...register("msrp" )}
                            placeholder="MRP"
                            type="text"
                          />
                          {errors?.msrp && (
                            <p className="text-danger">
                              {errors?.msrp?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label ">Rack </label>
                          <input
                            className="form-control"
                            {...register("rack")}
                            placeholder="Rack"
                            type="number"
                          />
                           {errors?.rack && (
                            <p className="text-danger">
                              {errors?.rack?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="card mb-4">
                  <div className="card-header">
                    <h4>Shipping</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="width">
                              Width (inches)
                            </label>
                            <input
                              className="form-control"
                              id="width"
                              type="text"
                              // {...register("width")}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="height">
                              Height (inches)
                            </label>
                            <input
                              className="form-control"
                              id="height"
                              type="text"
                              // {...register("height")}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="weight">
                              Weight (grams)
                            </label>
                            <input
                              className="form-control"
                              id="weight"
                              type="text"
                              // {...register("weight")}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                              htmlFor="shipping_fees"
                            >
                              Shipping Fees ($)
                            </label>
                            <input
                              className="form-control"
                              id="shipping_fees"
                              type="text"
                              // {...register("shipping_fees")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> */}
              </Col>

              <Col lg={4}>
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Media</h4>
                  </div>
                  <div className="card-body">
                    <div className="input-upload">
                     {/* {imgAdd ? 
                     ( image && image.map((data)=>( 
                      <img src={URL.createObjectURL(data)} alt=""  style={{height:"100px",width:"100px",margin:"3px "}} />
                   )))
                     
                     : */}
                   {  console.log(imagePreview,'fhgjgk')}
                     
                    { ( Array.isArray(imagePreview) && imagePreview?.map((data,index)=>(
                      
                      console.log(data[0] instanceof File,Array.isArray(data),'fjgkj'),
                      
                      data[0] instanceof File && Array.isArray(data)?
                     
                        
                          data.map((file)=>(
                            <div className="info " key = {index}>
                      <span className="close"  onClick= {()=>removeImage(index)} >
                         <span
                           className="btn-close   ">
                           &times;
                         </span>
                       </span>
                       <img 
                       src = {URL.createObjectURL(file)  } 
                       alt ="img1" 
                       style={{height:"100px",width:"100px"}}/> 
                       </div>

                          ))
                        
                       
                     :  
                      <div className="info " key = {index}>
                      <span className="close"  onClick= {()=>removeImage(index)} >
                         <span
                           className="btn-close   ">
                           &times;
                         </span>
                       </span>
                       
                       <img 
                       src = {API_BASE + data} 
                       alt ="img" 
                       style={{height:"100px",width:"100px"}}/> 
                     </div>

                    //   <div className="info " key = {index}>
                    //   <span className="close"  onClick= {()=>removeImage(index)} >
                    //      <span
                    //        className="btn-close   ">
                    //        &times;
                    //      </span>
                    //    </span>
                       
                    //    <img 
                    //    src = {data instanceof File
                    //     ? URL.createObjectURL(data) 
                    //     : API_BASE + data} 
                    //    alt ="img" 
                    //    style={{height:"100px",width:"100px"}}/> 
                    //  </div>
                   )))}
                     
                     {/* } */}
                     
                     
                      <input
                        className="form-control"
                        type="file"
                        multiple
                        ref={imageRef}
                        onChange={handleImageChange}
                      />
                     
                    </div>
                  </div>
                </div>

                <div className="card mb-4 overflow-visible">
                  <div className="card-header">
                    <h4>Category & Brand</h4>
                  </div>
                  <div className="card-body">
                    <div className="row gx-2">
                      <div className="col-sm-6 mb-3">
                        <label className="form-label required">Category</label>
                        {/* <select
                          className="form-select"
                          {...register("catgory_id")}
                        >
                          <option value={selectDefault} disabled>
                            SELECT
                          </option>
                          {getCatData?.data?.map((cat) => (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                        {errors?.catgory_id && (
                          <p className="text-danger">
                            {errors?.catgory_id?.message}
                          </p>
                        )} */}
                        <Select
                          placeholder="Category"
                          className="form-control"
                          options={getCatData?.data || []}
                          disabled={false}
                          dropdownHandle={true}
                          searchable={true}
                          searchBy="name"
                          labelField={"name"}
                          valueField={"id"}
                          name="catgg"
                          values={
                            getCatData?.data?.filter(
                              (ele) => ele.id === getValues("catgory_id")
                            ) || []
                          }
                          required
                          onChange={(selectedOption) => {
                            setValue("catgory_id", selectedOption[0]?.id);
                          }}
                        />
                      </div>

                      <div className="col-sm-6 mb-3">
                        <label className="form-label required">Brand</label>
                        {/* <select
                          className="form-select"
                          {...register("brand_id")}
                        >
                          <option value={selectDefault} disabled>
                            SELECT
                          </option>
                          {getBrandData?.data?.map((brand) => (
                            <option key={brand.id} value={brand.id}>
                              {brand?.name}
                            </option>
                          ))}
                          {errors?.brand_id && (
                            <p className="text-danger">
                              {errors?.brand_id?.message}
                            </p>
                          )}
                        </select> */}
                        <Select
                          placeholder="Brand"
                          className="form-control"
                          options={getBrandData?.data || []}
                          disabled={false}
                          dropdownHandle={true}
                          searchable={true}
                          searchBy="name"
                          labelField={"name"}
                          valueField={"id"}
                          values={
                            getBrandData?.data?.filter(
                              (ele) => ele.id === getValues("brand_id")
                            ) || []
                          }
                          name="brand"
                          onChange={(selectedOption) => {
                            setValue("brand_id", selectedOption[0]?.id);
                          }}
                          required
                        />
                      </div>

                      {/* <div className="mb-4">
                        <label className="form-label">Tags</label>
                        <input
                          className="form-control"
                          // {...register("tags")}
                          placeholder="Type here"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </section>

        <Footer />
      </main>
    </>
  );
}

export default Pageformproduct;
