import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Category } from "../../utils/Constants";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductPriceRequest,
  postProductPriceRequest,
} from "../../store/product/product.action";
import { productSelector } from "../../store/product/product.model";

function ProfitMargin() {
  const dispatch = useDispatch();
  const { cat, postPriceSuccess } = useSelector(productSelector);
  console.log(cat, "ccc");
  const [localCat, setLocalCat] = useState();
  console.log(localCat, "loo");

  const [file, setFile] = useState(
  //   [
  //   { retial: "", wholeSale: "", credit: "", category: 1 },
  //   { retial: "", wholeSale: "", credit: "", category: 2 },
  //   { retial: "", wholeSale: "", credit: "", category: 3 },
  //   { retial: "", wholeSale: "", credit: "", category: 4 },
  //   { retial: "", wholeSale: "", credit: "", category: 5 },
  //   { retial: "", wholeSale: "", credit: "", category: 6 },
  //   { retial: "", wholeSale: "", credit: "", category: 7 },
  // ]
);

  const [fileData, setFileData] = useState();
  const fileChange = (value, field, index) => {
    let temp = [...file];
    temp[index] = { ...temp[index], [field]: value };
    // console.log(temp);
    setFile(temp);

    // console.log(file[cat]?.[field],"yyy");
    // setFile((prev)=>{return {...prev,[cat]:{...prev[cat],[field]:value}}})
  };

  // console.log(file,"fileA");

  const priceChamge = (price, index, name, id) => {
    setLocalCat((prev) => {
      const updatedc = [...prev];
      //    updatedc[index]={price_category:name , profit_percentage:Number(price),  }
      updatedc[index] = {
        price_category_id: id,
        profit_percentage: String(price),
        price_category: name,
      };
      return updatedc;
    });
  };
  const submitCat = () => {
    console.log(file, "fileA");
    let data = file;
    let payload = {
      categories: data,
    };
    // postProductPriceRequest
    dispatch(postProductPriceRequest(payload));
  };
  const submit = () => {
    let data = localCat.map((c) => {
      if (c.profit_percentage === "") {
        c.profit_percentage = 0;
      }
      return c;
    });
    const fdata = data.map((ele) => {
      console.log(ele, "ooo");
      return {
        price_category_id: ele.price_category_id,
        profit_percentage: ele.profit_percentage,
      };
    });
    console.log(fdata, "ooo");
    let payload = {
      categories: fdata,
    };
    // postProductPriceRequest
    dispatch(postProductPriceRequest(payload));
    // dispatch(getProductPriceRequest());
  };
  useEffect(() => {
    dispatch(getProductPriceRequest());
  }, [dispatch]);

  useEffect(() => {
    if (postPriceSuccess) {
      dispatch(getProductPriceRequest());
    }
  }, [postPriceSuccess, dispatch]);

  useEffect(() => {
    if (cat && cat.length > 0) {
      const updatedCategories = cat.map((c) => ({
        price_category_id: c.id,
        profit_percentage: c.profit_percentage,
        price_category: c.price_category,
      }));
      setLocalCat(updatedCategories);
    }
  }, [cat]);
  useEffect(() => {
    setFile(
      [
        { retial: "", wholeSale: "", credit: "", category: 1 },
        { retial: "", wholeSale: "", credit: "", category: 2 },
        { retial: "", wholeSale: "", credit: "", category: 3 },
        { retial: "", wholeSale: "", credit: "", category: 4 },
        { retial: "", wholeSale: "", credit: "", category: 5 },
        { retial: "", wholeSale: "", credit: "", category: 6 },
        { retial: "", wholeSale: "", credit: "", category: 7 },
      ]
    );
  }, []);
  useEffect(() => {
    if (file) {
      let data = [];
      console.log(file, "po99");

      const updated = file.map((ele) => {
        const newdata = cat.find((ans) => {
          console.log(ans.category, ele, "78945");

          if (ans.category == ele.category) {
            return ans;
          }
        });
        console.log(newdata, "7896");

        data.push(newdata);
      });
      console.log(data, "gggg");

      const filtered = data?.map((ele) => {
        return {
          retial: ele?.retial,
          wholeSale: ele?.wholeSale,
          credit: ele?.credit,
          category: ele?.category,
        };
      });
      console.log(filtered, "789");
      setFile(filtered);
     
    }
  }, [cat]);
  console.log(cat, file, "ggg");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div className="col-8 row">
              <h2 className="content-title">Profit Margin</h2>
              <p>Edit Profit Margin</p>
            </div>
          </div>
          {/* <div className="card mb-4">
            <div className="card-header">
              <h4>Profit Margin</h4>
            </div>
            <div className="card-body row">
              <div className="col-6">
                <Table>
                  <thead>
                    <tr>
                      <td>Category</td>
                      <td>Profit Margin</td>
                    </tr>
                  </thead>
                  <tbody>
                    {localCat &&
                      localCat.map((c, index) => {
                        return (
                          <tr>
                            <td>
                              <label>{c.price_category}</label>
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={c.profit_percentage}
                                onChange={(e) =>
                                  priceChamge(
                                    e.target.value,
                                    index,
                                    c.price_category,
                                    c.price_category_id
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <button className="btn btn-primary " onClick={submit}>
                          Save
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div> */}
          <div className="card mb-4">
            <div className="card-header">
              <h4>Profit Margin</h4>
            </div>
            <div className="card-body row">
              <div className="col-6">
                <Table>
                  <thead>
                    <tr>
                      <td>Category</td>
                      <td>Retail</td>
                      <td>Wholesale</td>
                      <td>Credit</td>
                    </tr>
                  </thead>
                  <tbody>
                    {file?.map((data)=>{
                      return (<tr>
                        {data.category ==1 &&<td>A</td>}
                        {data.category ==2 &&<td>B</td>}
                        {data.category ==3 &&<td>C</td>}
                        {data.category ==4 &&<td>D</td>}
                        {data.category ==5 &&<td>E</td>}
                        {data.category ==6 &&<td>F</td>}
                        {data.category ==7 &&<td>G</td>}
                        
                        <td>
                        <input
                          type="text"
                          value={data?.retial}
                          className="form-control"
                          onChange={(e) =>
                            fileChange(e.target.value, "retial", (Number(data.category)-1) )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={data?.wholeSale}
                          onChange={(e) =>
                            fileChange(e.target.value, "wholeSale", (Number(data.category)-1) )
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={data?.credit}
                          onChange={(e) =>
                            fileChange(e.target.value, "credit", (Number(data.category)-1) )
                          }
                        />
                      </td>
                      </tr>)
                    })}
                    <tr>
                      
                      <td colSpan={4} className="text-end">
                        <button
                          className="btn btn-primary "
                          onClick={submitCat}
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  {/* <tbody>
                    <tr>
                      <td>A</td>
                      <td>
                        <input
                          type="text"
                          value={file[0]?.retial}
                          className="form-control"
                          onChange={(e) =>
                            fileChange(e.target.value, "retial", 0)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[0]?.wholeSale}
                          onChange={(e) =>
                            fileChange(e.target.value, "wholeSale", 0)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[0]?.credit}
                          onChange={(e) =>
                            fileChange(e.target.value, "credit", 0)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>B</td>
                      <td>
                        <input
                          type="text"
                          value={file[1]?.retial}
                          className="form-control"
                          onChange={(e) =>
                            fileChange(e.target.value, "retial", 1)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[1]?.wholeSale}
                          onChange={(e) =>
                            fileChange(e.target.value, "wholeSale", 1)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[1]?.credit}
                          onChange={(e) =>
                            fileChange(e.target.value, "credit", 1)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>
                        <input
                          type="text"
                          value={file[2]?.retial}
                          className="form-control"
                          onChange={(e) =>
                            fileChange(e.target.value, "retial", 2)
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[2]?.wholeSale}
                          onChange={(e) =>
                            fileChange(e.target.value, "wholeSale", 2)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[2]?.credit}
                          onChange={(e) =>
                            fileChange(e.target.value, "credit", 2)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>D</td>
                      <td>
                        <input
                          type="text"
                          value={file[3].retial}
                          className="form-control"
                          onChange={(e) =>
                            fileChange(e.target.value, "retial", 3)
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[3]?.wholeSale}
                          onChange={(e) =>
                            fileChange(e.target.value, "wholeSale", 3)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[3]?.credit}
                          onChange={(e) =>
                            fileChange(e.target.value, "credit", 3)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>E</td>
                      <td>
                        <input
                          type="text"
                          value={file[4].retial}
                          className="form-control"
                          onChange={(e) =>
                            fileChange(e.target.value, "retial", 4)
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[4]?.wholeSale}
                          onChange={(e) =>
                            fileChange(e.target.value, "wholeSale", 4)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[4]?.credit}
                          onChange={(e) =>
                            fileChange(e.target.value, "credit", 4)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>F</td>
                      <td>
                        <input
                          type="text"
                          value={file[5].retial}
                          className="form-control"
                          onChange={(e) =>
                            fileChange(e.target.value, "retial", 5)
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[5].wholeSale}
                          onChange={(e) =>
                            fileChange(e.target.value, "wholeSale", 5)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[5].credit}
                          onChange={(e) =>
                            fileChange(e.target.value, "credit", 5)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>G</td>
                      <td>
                        <input
                          type="text"
                          value={file[6].retial}
                          className="form-control"
                          onChange={(e) =>
                            fileChange(e.target.value, "retial", 6)
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[6].wholeSale}
                          onChange={(e) =>
                            fileChange(e.target.value, "wholeSale", 6)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={file[6].credit}
                          onChange={(e) =>
                            fileChange(e.target.value, "credit", 6)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="text-end">
                        <button
                          className="btn btn-primary "
                          onClick={submitCat}
                        >
                          Save
                        </button>
                      </td>
                    </tr>
                  </tbody> */}
                </Table>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ProfitMargin;
