import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Footer from "../Footer";
import _ from "loadsh";
import {
  ProductAddListValiator,
  UsersAddValidator,
  VendorAddValidator,
} from "../../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserDataRequest,
  getIdDataRequest,
  getScreenRequest,
  getUserDataRequest,
  logout,
  updateUserRequest,
  userSelector,
} from "../../store/user/user.action";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-dropdown-select";
import { usersTypes, vendorTypes } from "../../utils/Constants";
import { NodeServer } from "../../services/config";

const PageUsersAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();
  console.log(id,'lkjhg');
  
  const { screens } = useSelector(userSelector);
  console.log(screens, "jdfksj");

  console.log(id, "poiu");
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [DynamicScreen, setDynamicScreen] = useState();
  const [selectedActions, setSelectedActions] = useState({});
  console.log(selectedActions, "selectedActions");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(UsersAddValidator),
  });
  /* Selectors */
  const { createSuccess, userData, updateSuccess,idData } = useSelector(userSelector);
  console.log(userData, "userDAsa");
  console.log(idData, "idData");

  /* State */
  const [ids, setId] = useState(0);
  const [photo, setPhoto] = useState();
  console.log(ids, "sds");

  /* Fucntions */
  const CapFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const onSubmitHandler = (formData) => {
    // formData.role_id =4;
    formData.photo = photo;

    formData.screen = selectedActions;
    console.log(formData, "qsc");

    if (formData.credit_limit == "") {
      formData.credit_limit = 0;
    }
    if (formData.duedays_limit == "") {
      formData.duedays_limit = 0;
    }
    console.log(formData, "sldjfsdsd");
    if (ids == 0) {
      dispatch(createUserDataRequest(formData));
    } else {
      const payload = {
        id: ids,
        formData,
      };
      formData.id = ids;
      console.log(formData, "createformdkf");
      dispatch(updateUserRequest(formData));
    }
  };
  const changeType = (sel) => {
    console.log(sel, "qwert");

    setValue("role_id", sel[0]?.value);
    setSelectedOption2(sel);
  };

  const handleCheckboxChange = (screenName, action) => {
    console.log(screenName,'screenName');
    
    const key = `${screenName}-${action}`;
    setSelectedActions((prevSelectedActions) => {
      const updatedActions = { ...prevSelectedActions };

      if (updatedActions[key]) {
        delete updatedActions[key];
      } else {
        updatedActions[key] = true;
      }
      console.log(updatedActions, "4567890");

      return updatedActions;
    });
  };
  /* useEffect */
  useEffect(() => {
    if (id && id.id) {
      setId(id.id);
    }
  }, [id]);

  useEffect(() => {
    // if (ids > 0) {
    //   const payload = {
    //     query: "",
    //     page: 0,
    //     limit: 0,
    //     userId: ids,
    //   };
    //   dispatch(getUserDataRequest(payload));
    // }
    dispatch(getIdDataRequest(ids));
  }, [ids]);
  useEffect(() => {
    if (ids>0 ) {
      setPhoto(
        idData?.image?.includes("/products/users/")
          ? NodeServer.baseURL + idData?.response?.photo
          : NodeServer.baseURL + "/products/users/" + idData?.response?.photo
      );
      console.log(photo, "pkm");
      setSelectedActions(idData?.response?.screen||{});
      console.log(idData,'sfjgh');
      console.log(idData?.response?.role_id,'sfjgh');
      let selOPt=usersTypes.find((ele)=>ele?.value ==idData?.response?.role_id)
      console.log(selOPt,'sfjgh');
      
      selOPt && setSelectedOption2([selOPt])
      reset({
        email: idData?.response?.email,
        mobile: idData?.response?.mobile,
        name: idData?.response?.name,
        // password: idData?.response?.password,
        role_id: idData?.response?.role_id,
        gst_no: idData?.response?.gst_no,

        address: idData?.response?.address,
        zipcode: idData?.response?.zipcode,
        city: idData?.response?.city,

        contact_person: idData?.response?.contact_person,
        area: idData?.response?.area,
      });
    }

      // if (idData?.response ) {
      //   userData?.data?.map((ele) => {
      //     let role_id = _.filter(usersTypes, { value: ele?.role_id });
      //     console.log(ele, "plm");
      //     // const userScreen = JSON.parse(ele?.screen);
      //     // const userScreen = selectedActions;
      //     // console.log(userScreen, "hgkh");
  
      //     console.log(role_id[0], "Vtype");
  
      //     let opt = role_id[0];
      //     console.log(opt, "vtype");
      //     changeType(role_id);
      //     setPhoto(
      //       ele?.image?.includes("/products/users/")
      //         ? NodeServer.baseURL + idData?.response?.photo
      //         : NodeServer.baseURL + "/products/users/" + idData?.response?.photo
      //     );
      //     console.log(photo, "pkm");
      //     setSelectedActions(idData?.response?.screen||{});
  
      //     reset({
      //       email: idData?.response?.email,
      //       mobile: idData?.response?.mobile,
      //       name: idData?.response?.name,
      //       password: idData?.response?.password,
      //       role_id: idData?.response?.role_id,
  
      //       address: idData?.response?.address,
      //       zipcode: idData?.response?.zipcode,
      //       city: idData?.response?.city,
  
      //       contact_person: idData?.response?.contact_person,
      //       area: idData?.response?.area,
      //     });
      //   });
      // }
    
    
  }, [idData]);
  useEffect(() => {
    if (createSuccess || updateSuccess) {
      const localId = localStorage.getItem("id");
      if (id == localId) {
        // const payload = {
        //   query: "",
        //   page: 0,
        //   limit: 0,
        //   userId:""
        // };
        // dispatch(getUserDataRequest(payload))
      }
      navigate("/pageusers");

      dispatch(logout());
      reset();
    }
  }, [createSuccess, updateSuccess]);
  // console.log(screen, "jdsfjskdj");
  useEffect(() => {
    dispatch(getScreenRequest());
    
  }, []);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <form
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Row>
              <Col md={12}>
                <div className="content-header">
                  <h2 className="content-title">
                    {ids === 0 || ids == undefined ? "Add User " : "Edit User "}
                  </h2>
                  <div>
                    <button
                      className="btn btn-md rounded font-sm hover-up"
                      type="submit"
                    >
                      {" "}
                      {ids === 0 || ids == undefined
                        ? "Save User"
                        : "Update User"}
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Basic Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="name">
                          Name
                        </label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          {...register("name")}
                          placeholder="Enter your name"
                        />
                        {errors?.name && (
                          <p className="text-danger">{errors?.name?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="contact_person">
                          Contact Person
                        </label>
                        <input
                          className="form-control"
                          id="contact_person"
                          type="text"
                          {...register("contact_person")}
                          placeholder="Enter your Contact Person"
                        />
                        {errors?.contact_person && (
                          <p className="text-danger">
                            {errors?.contact_person?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="photo">
                          Photo
                        </label>
                        <input
                          className="form-control"
                          id="photo"
                          type="file"
                          onChange={(e) => {
                            console.log(e.target.value, "etv");
                            setPhoto(e.target.files[0]);
                          }}
                          // {...register("name")}
                          // placeholder="Enter your name"
                        />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="email">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          {...register("email")}
                          placeholder="Enter your email"
                        />
                        {errors?.email && (
                          <p className="text-danger">
                            {errors?.email?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="mobile">
                          Mobile
                        </label>
                        <input
                          className="form-control"
                          id="mobile"
                          type="tel"
                          {...register("mobile")}
                          placeholder="Enter your mobile number"
                        />
                        {errors?.mobile && (
                          <p className="text-danger">
                            {errors?.mobile?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="password">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="password"
                          type="password"
                          {...register("password")}
                          placeholder="Enter your password"
                        />
                        {errors?.password && (
                          <p className="text-danger">
                            {errors?.password?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="role_id">
                          GST Number
                        </label>
                        <input
                          className="form-control"
                          id="role_id"
                          type="text"
                          {...register("gst_no")}
                          placeholder="Enter GST Number"
                        />
                        {errors?.gst_no && (
                          <p className="text-danger">
                            {errors?.gst_no?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="city">
                          City
                        </label>
                        <input
                          className="form-control"
                          id="city"
                          type="text"
                          {...register("city")}
                          placeholder="Enter your city"
                        />
                        {errors?.city && (
                          <p className="text-danger">{errors?.city?.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="zipcode">
                          Zipcode
                        </label>
                        <input
                          className="form-control"
                          id="zipcode"
                          type="text"
                          {...register("zipcode")}
                          placeholder="Enter your zipcode"
                        />
                        {errors?.zipcode && (
                          <p className="text-danger">
                            {errors?.zipcode?.message}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="address">
                          Address
                        </label>
                        <textarea
                          className="form-control"
                          id="address"
                          {...register("address")}
                          placeholder="Enter your address"
                          rows="3"
                        ></textarea>
                        {errors?.address && (
                          <p className="text-danger">
                            {errors?.address?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="area">
                          Area
                        </label>
                        <input
                          className="form-control"
                          id="area"
                          type="text"
                          {...register("area")}
                          placeholder="Enter Your Area"
                        />
                      </div>
                      {/* <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="role_id">
                          Credit Limit
                        </label>
                        <input
                          className="form-control"
                          id="role_id"
                          type="text"
                          {...register("credit_limit")}
                          placeholder="Enter Credit Limit"
                        />
                        {errors?.credit_limit && (
                          <p className="text-danger">
                            {errors?.credit_limit?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="city">
                          Due Days Limit
                        </label>
                        <input
                          className="form-control"
                          id="city"
                          type="number"
                          {...register("duedays_limit")}
                          placeholder="Enter Due Days"
                        />
                        {errors?.duedays_limit && (
                          <p className="text-danger">
                            {errors?.duedays_limit?.message}
                          </p>
                        )}
                      </div> */}
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="name">
                          User Type
                        </label>
                        <Select
                          placeholder="User Type"
                          className="form-control"
                          options={usersTypes || []}
                          disabled={false}
                          dropdownHandle={true}
                          searchable={true}
                          searchBy="name"
                          dropdownPosition="top"
                          labelField={"name"}
                          valueField={"value"}
                          values={selectedOption2 || []}
                          name="name"
                          onChange={(selectedOption) => {
                            // console.log(selectedOption2[0],'vtype');

                            // setValue("vendor_type", selectedOption[0]?.value);
                            // setSelectedOption2(selectedOption);
                            changeType(selectedOption);
                          }}
                        />
                        {errors?.role_id && (
                          <p className="text-danger">
                            {errors?.role_id?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="mb-5">
                {screens &&
                  screens?.map((screen) => (
                    <div className="col-12 mt-5 mb-5 ">
                      <div key={screen.id} className="mb-3">
                        <h3>{CapFirstLetter(screen.screen_name)}</h3>
                        <div className="col-6 mb-3">
                          <div class="form-check d-flex justify-content-between">
                            {Object.keys(screen?.actions || {}).map(
                              (action) => {
                                // const actionKey = `${screen.screen_name}-${action}`;
                                const actionKey = `${
                                  screen.screen_name || "unknown"
                                }-${action || "unknown"}`;

                                return (
                                  <label key={action} class="form-check-label">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={
                                        selectedActions[actionKey] || false
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(
                                          screen.screen_name,
                                          action
                                        )
                                      }
                                    />
                                    {CapFirstLetter(action)}
                                  </label>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Col>
            </Row>
          </form>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default PageUsersAdd;
