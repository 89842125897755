import React, { useEffect } from "react";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { creditDueDataRequest } from "../../store/order/order.action";
import { orderSelector } from "../../store/order/order.model";
import { getPurchaseReminderDataRequest } from "../../store/PurchaseCompany/pc.action";
import { companySelector } from "../../store/PurchaseCompany/pc.model";

export const PurchaseReminder = () => {
  const dispatch = useDispatch();
  const { creditDueData } = useSelector(orderSelector);
  const { purchaseReminderData } = useSelector(companySelector);
  console.log(creditDueData, "creditDueData");
  console.log(purchaseReminderData, "purchaseReminderData");
  /* useEffect */
  useEffect(() => {
    dispatch(creditDueDataRequest({}));
    const payload={
        amt:5000000,
    }
    dispatch(getPurchaseReminderDataRequest(payload))
  }, []);
  return (
    <div>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Purchase Reminder Above 5 Lakhs</h2>
              <p>Important insights of your business here</p>
            </div>
          </div>
          <div className="card mb-4">
            {/* <header className="card-header">
              <h4 className="card-title">Due Days Exceeded List</h4>
            </header> */}
            <div className="card-body">
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="align-middle" scope="col">
                          Company Name
                        </th>
                   
                        <th className="align-middle" scope="col">
                          contact Number
                        </th>
                        <th className="align-middle" scope="col">
                         Email
                        </th>
                        <th className="align-middle" scope="col">
                          Totl Amount
                        </th>
                       </tr>
                    </thead>
                    <tbody>
                        {purchaseReminderData?.dataa?.map((ele)=>{
                            return(
                                <tr>
                                    <td>{ele?.company_name}</td>
                                    <td>{ele?.contact_number}</td>
                                    <td>{ele?.contact_number}</td>
                                    <td>{ele?.order_amount}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="card mb-4">
            <header className="card-header">
              <h4 className="card-title">Credit Limit Exceeded List</h4>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="align-middle" scope="col">
                          Vendor ID
                        </th>
                        <th className="align-middle" scope="col">
                          Vendor Name
                        </th>

                        <th className="align-middle" scope="col">
                          Credit Limit
                        </th>

                        <th className="align-middle" scope="col">
                          Invoice Total <br />
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {creditDueData
                        .filter(
                          (ele) => ele?.totalUnpaidAmount > ele?.creditLimit
                        )
                        ?.map((ele, indx) => {
                          return (
                            <tr>
                              <td>
                                <a className="fw-bold" href="#">
                                  {ele?.userId}
                                </a>
                              </td>
                              <td> {ele?.name}</td>
                              <td>
                                {" "}
                                {ele?.creditLimit ? ele?.creditLimit : "0"}
                              </td>
                              <td>{ele?.totalUnpaidAmount}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      </main>
    </div>
  );
};
