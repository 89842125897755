import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseCompanyDataRequest } from "../../store/PurchaseCompany/pc.action";
import Select from "react-dropdown-select";
import { productSelector } from "../../store/product/product.model";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
import { DateRangePicker } from "rsuite";
import { PurchaseReportPayload } from "../../utils/config";
import {
  getAllPurchaseOrderDataRequest,
  getPurchaseOrderDataRequest,
  poStateReset,
} from "../../store/PurchaseOrder/po.action";
import { purchaseOrderSelector } from "../../store/PurchaseOrder/po.model";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { TaxPercentage } from "../../utils/Constants";
function ReportPurchaseList() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { getProductData, productDeleteSucces } = useSelector(productSelector);
  const { companyData } = useSelector(companySelector);
  let { invoiceData, allInvoice } = useSelector(purchaseOrderSelector);
  console.log(invoiceData, "invoicedad");
  const { afterToday } = DateRangePicker;
  const [value, setValues] = useState(null);
  console.log(companyData, "lssl");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const totalPages1 = Math.ceil(invoiceData?.count / rowsPerPage) || 1;
  const [searchQuery, setSearchQuery] = useState("");
  const currentPage = page; // Replace with your actual current page state
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const filter = (formData) => {
    console.log(formData, "clgds");
    let payload = PurchaseReportPayload(
      currentPage,
      rowsPerPage,
      "",
      formData.customer,
      "",
      value?.[0],
      value?.[1]
    );
    dispatch(getPurchaseOrderDataRequest(payload));

    let payload1 = PurchaseReportPayload(
      1,
      0,
      "",
      formData.customer,
      "",
      value?.[0],
      value?.[1]
    );
    dispatch(getAllPurchaseOrderDataRequest(payload1));
  };
  console.log(allInvoice, invoiceData, "allInvoice");
  const handleDownload = () => {
    const purchaseData = allInvoice?.rows?.map((invoice) => {
      const details = invoice?.purchase_invoice_details[0];
      const taxableAmount = parseFloat(details?.amount);
      
      const taxPercentage = TaxPercentage.find((t) => {
        return details?.tax === t.name;
      })?.percent || 0;
      
      const cgst = (taxPercentage / 2 / 100) * taxableAmount; 
      const sgst = (taxPercentage / 2 / 100) * taxableAmount; 
      const igst = (taxPercentage / 100) * taxableAmount;     
      
      return {
        "Invoice No": invoice?.invoice_no,
        "Invoice Date": invoice?.purchase_date,
        "ACCOUNT Name": invoice.purchasecompany?.company_name,
        "Item Group": "Electronics",
        "Item Description": details?.products?.description,
        "Qty": details?.qty,
        "Price": details?.price,
        "Discount Value": details?.discount_val || 0,
        "Taxable Amount": taxableAmount.toFixed(2),
        "CGST": cgst.toFixed(2),
        "SGST": sgst.toFixed(2),
        "IGST": igst.toFixed(2),
        "Invoice Total": invoice?.total_amount,
        "Rack": details?.products?.rack || "Not Available",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(purchaseData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  const { register, handleSubmit, setValue, reset, watch } = useForm();
  useEffect(() => {
    const payload = {
      query: "",
      page: "",
      limit: "",
      id: "",
    };
    dispatch(getPurchaseCompanyDataRequest(payload));
    dispatch(poStateReset());
  }, []);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Purchase Report</h2>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <form onSubmit={handleSubmit(filter)}>
                <div className="row ">
                  <div className="col-3">
                    <label className="form-label" htmlFor="order_customer">
                      Customer Name
                    </label>
                    <Select
                      placeholder="Customer"
                      className="form-control"
                      options={[
                        { id: "", company_name: "All" },
                        ...(Array.isArray(companyData?.data)
                          ? companyData?.data
                          : []),
                        ,
                      ]}
                      disabled={false}
                      dropdownHandle={true}
                      searchable={true}
                      searchBy="name"
                      labelField={"company_name"}
                      valueField={"id"}
                      name="name"
                      onChange={(selectedOption) =>
                        setValue("customer", selectedOption[0].id)
                      }
                      // value={selectedCustomer}
                      // onChange={handleCustomerChange}
                    />
                  </div>
                  <div className="col-3">
                    <label className="form-label" htmlFor="order_total">
                      Date Range
                    </label>
                    <DateRangePicker
                      className="d-flex"
                      showOneCalendar
                      shouldDisableDate={afterToday()}
                      value={value}
                      onChange={(newValue) => setValues(newValue)}
                      placeholder="Select Date Range"
                    />
                  </div>
                  <div className="col-3">
                    {/* <label className="form-label">Order Status</label>
                    <select
                      className="form-select"
                      value={watch("order_status")}
                      onChange={(e) => {
                        setValue("order_status", e.target.value);
                      }}
                    >
                      <option className="option" value={""}>
                        All
                      </option>
                      <option className="option" value={1}>
                        ONHOLD
                      </option>
                      <option className="option" value={2}>
                        DISPATCHED
                      </option>
                      <option className="option" value={3}>
                        DELIVERY IN PROCESS
                      </option>
                      <option className="option" value={4}>
                        DELIVERED
                      </option>
                      <option className="option" value={5}>
                        COMPLETED
                      </option>
                      <option className="option" value={6}>
                        PAID
                      </option>
                    </select> */}
                  </div>
                  <div className="col-3 d-flex justify-content-evenly align-items-end">
                    <button
                      title="Filter Data"
                      className="btn btn-md rounded font-sm px-5 "
                      type="submit"
                    >
                      Filter
                    </button>
                    <button
                      title="Export Excel"
                      className="btn btn-md rounded font-sm px-5"
                      type="button"
                      onClick={handleDownload}
                    >
                      Export
                    </button>
                  </div>
                </div>
              </form>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Invoice No</th>
                      <th>Invoice Date</th>
                      <th>Company Name</th>
                      <th>Invoice Amt</th>
                      {/* <th>GST NO</th> */}
                      {/* <th>Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData?.rows?.map((ele, ind) => {
                      return (
                        <tr>
                          <td width="20%">
                            <a className="itemside" href="#">
                              <div className="info pl-3">
                                <h6 className="mb-0 title">
                                  {ele?.invoice_no}
                                </h6>
                              </div>
                            </a>
                          </td>
                          <td>
                            {dayjs(ele?.purchase_date).format("DD/MM/YYYY")}
                          </td>
                          <td>{ele.purchasecompany?.company_name}</td>
                          <td>{ele.total_amount}</td>
                          {/* <td>
                            <span className="badge rounded-pill alert-success">
                              {ele?.status == 0 ? "Active" : "Unactive"}
                            </span>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-15 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {pagesToDisplay.map((page) => {
                  return page != "..." ? (
                    <li
                      key={page}
                      className={`page-item b ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ) : page == "..." ? (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ) : (
                    ""
                  );
                })}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default ReportPurchaseList;
