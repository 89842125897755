import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AppRoute from "./routes/route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {initLightboxJS} from 'lightbox.js-react'
import { useEffect } from "react";

function App() {
  // useLayout();
  useEffect(() => {
    initLightboxJS("Insert your License Key here", "Insert plan type here");
  }, []);
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route exact path={route?.path} element={<route.component />} />
          ))}
          <Route path="/" element={<AppRoute />}>
            {authProtectedRoutes.map((route, idx) => (
              <Route exact path={route?.path} element={<route.component />} />
            ))}
          </Route>

          <Route
            path="*"
            element={
              <div className="text-center d-flex justify-content-center align-items-center s100vh">
                {" "}
                <b> Not Found !! </b>
              </div>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
