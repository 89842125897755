import { action } from "typesafe-actions";
import { purchaseCompanyActionTypes } from "./pc.model";
import { purchaseOrderActionTypes } from "../PurchaseOrder/po.model";

export const getPurchaseCompanyDataRequest = (req) =>
  action(purchaseCompanyActionTypes.GETPURCHASECOMPANYDATA_REQUEST, req);
export const getPurchaseCompanyDataSuccess = (res) =>
  action(purchaseCompanyActionTypes.GETPURCHASECOMPANYDATA_SUCCESS, res);
export const getPurchaseCompanyDataFailure = (err) =>
  action(purchaseCompanyActionTypes.GETPURCHASECOMPANYDATA_FAILURE, err);

export const postPurchaseCompanyDataRequest = (req) =>
  action(purchaseCompanyActionTypes.POSTPURCHASECOMPANYDATA_REQUEST, req);
export const postPurchaseCompanyDataSuccess = (res) =>
  action(purchaseCompanyActionTypes.POSTPURCHASECOMPANYDATA_SUCCESS, res);
export const postPurchaseCompanyDataFailure = (err) =>
  action(purchaseCompanyActionTypes.POSTPURCHASECOMPANYDATA_FAILURE, err);

export const updatePurchaseCompanyDataRequest = (req) =>
  action(purchaseCompanyActionTypes.UPDATEPURCHASECOMPANYDATA_REQUEST, req);
export const updatePurchaseCompanyDataSuccess = (res) =>
  action(purchaseCompanyActionTypes.UPDATEPURCHASECOMPANYDATA_SUCCESS, res);
export const updatePurchaseCompanyDataFailure = (err) =>
  action(purchaseCompanyActionTypes.UPDATEPURCHASECOMPANYDATA_FAILURE, err);

export const deletePurchaseCompanyDataRequest = (req) =>
  action(purchaseCompanyActionTypes.DELETEPURCHASECOMPANYDATA_REQUEST, req);
export const deletePurchaseCompanyDataSuccess = (res) =>
  action(purchaseCompanyActionTypes.DELETEPURCHASECOMPANYDATA_SUCCESS, res);
export const deletePurchaseCompanyDataFailure = (err) =>
  action(purchaseCompanyActionTypes.DELETEPURCHASECOMPANYDATA_FAILURE, err);

export const getPurchaseReminderDataRequest = (req) =>
  action(purchaseCompanyActionTypes.GETPURCHASEREMINDERDATA_REQUEST, req);
export const getPurchaseReminderDataSuccess = (res) =>
  action(purchaseCompanyActionTypes.GETPURCHASEREMINDERDATA_SUCCESS, res);
export const getPurchaseReminderDataFailure = (err) =>
  action(purchaseCompanyActionTypes.GETPURCHASEREMINDERDATA_FAILURE, err);


export const pcStateResetRequest=(req)=>
    action(purchaseCompanyActionTypes.PCSTATERESET_REQUEST, req)