
import { call, takeEvery , put} from "redux-saga/effects";
import * as purchaseCompanyAction from "./pc.action"
import { purchaseCompanyActionTypes } from "./pc.model";
import { deleteProductApi, getPCApi, getPRApi, postPCApi, updatePCApi } from "./pc.api";

function* handlegetPCData(action) {
    try {
      console.log(action.payload,"asdf")
      const response = yield call(getPCApi, action.payload);
      yield put(purchaseCompanyAction.getPurchaseCompanyDataSuccess(response));
      
    } catch (e) {
      yield put(purchaseCompanyAction.getPurchaseCompanyDataFailure(e));
    }
  }
  function* handlegetPRData(action) {
    try {
      console.log(action.payload,"asdf")
      const response = yield call(getPRApi, action.payload);
      yield put(purchaseCompanyAction.getPurchaseReminderDataSuccess(response));
      
    } catch (e) {
      yield put(purchaseCompanyAction.getPurchaseReminderDataFailure(e));
    }
  }
  function* handlepostPCData(action) {
    try {
      const response = yield call(postPCApi, action.payload);
      yield put(purchaseCompanyAction.postPurchaseCompanyDataSuccess(response));
      
    } catch (e) {
      yield put(purchaseCompanyAction.postPurchaseCompanyDataFailure(e));
    }
  }
  function* handleupdatePCData(action) {
    try {
      console.log(action.payload,"lll")
      const response = yield call(updatePCApi, action.payload);
      yield put(purchaseCompanyAction.updatePurchaseCompanyDataSuccess(response));
      
    } catch (e) {
      yield put(purchaseCompanyAction.updatePurchaseCompanyDataFailure(e));
    }
  }
  function* handledeletePCData(action) {
    try {
      const response = yield call(deleteProductApi, action.payload);
      yield put(purchaseCompanyAction.deletePurchaseCompanyDataSuccess(response));
      
    } catch (e) {
      yield put(purchaseCompanyAction.deletePurchaseCompanyDataFailure(e));
    }
  }
export function* PurchaseCompanySaga() {
    yield takeEvery(purchaseCompanyActionTypes.GETPURCHASECOMPANYDATA_REQUEST, handlegetPCData)
    yield takeEvery(purchaseCompanyActionTypes.GETPURCHASEREMINDERDATA_REQUEST, handlegetPRData)
    yield takeEvery(purchaseCompanyActionTypes.POSTPURCHASECOMPANYDATA_REQUEST, handlepostPCData)
    yield takeEvery(purchaseCompanyActionTypes.UPDATEPURCHASECOMPANYDATA_REQUEST, handleupdatePCData)
    yield takeEvery(purchaseCompanyActionTypes.DELETEPURCHASECOMPANYDATA_REQUEST, handledeletePCData)
    
}