import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Table,
} from "react-bootstrap";
import _ from "lodash";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import "../../App.css";
// import { paymentValidator } from "../../utils/Validation";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// import { API_STATUS, paymentType, paymentTypewithcheck } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-dropdown-select";
import dayjs from "dayjs";

import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import {
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  API_STATUS,
  paymentType,
  paymentTypewithcheck,
} from "../../utils/Constants";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { getUserDataRequest, userSelector } from "../../store/user/user.action";
import {
  getUserOrderDetailByIdRequest,
  orderStateResetRequest,
  postPaymentDataRequest,
} from "../../store/order/order.action";
import { orderSelector } from "../../store/order/order.model";
import { paymentValidator } from "../../utils/Validation";
import { purchaseOrderSelector } from "../../store/PurchaseOrder/po.model";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
import {
  getFilteredPaymentRequest,
  getPurchaseOrderDataRequest,
  poStateReset,
  postPurchasePaymentDataRequest,
} from "../../store/PurchaseOrder/po.action";
import { getPurchaseCompanyDataRequest } from "../../store/PurchaseCompany/pc.action";
// import {
//   customerData,
//   customersSelector,
// } from "../../store/reducer/CustomerReducer";
// import {
//   clearSinvLoadingDatas,
//   createPaymentDetails,
//   getPaymentList,
//   salesinvoiceSelector,
// } from "../../store/reducer/SalesInvoiceReducer";
// import { getInvoiceDetails } from "../../store/reducer/SalesInvoiceReducer";
function PurchasePaymentAdd() {
  const _ = require("lodash");
  let [sortBy, setSortBy] = useState("created_at");
  let [invoiceTotal, setInvoiceTotal] = useState(0);
  let [crditTotal, setCreditotal] = useState(0);
  let [debitTotal, setDebitTotal] = useState(0);
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companyData } = useSelector(companySelector);
  const { invoiceData, filteredPayment, postPaymentSuccess } = useSelector(
    purchaseOrderSelector
  );

  const [settingTz, setSettingTz] = useState([]);
  const [from_date, setFromDate] = useState({ from: null, to: null });
  const [Currency, setCurrency] = useState([]);
  console.log(filteredPayment?.data, "settingTz");
  console.log(postPaymentSuccess, "toatktjdsfj");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(paymentValidator),
  });
  const [error, setError] = useState({
    payment_type: false,
  });
  console.log(errors, "errokjfdfl");
  console.log(filteredPayment?.data, "filteredPayment?.data");
  const [activeTab, setActiveTab] = useState("5");
  const [total, setTotal] = useState(0);
  const [isWarning, setIsWarning] = useState(false);
  const [ids, setIds] = useState([]);
  console.log(ids, "selected Ids");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const payload = {
      query: "",
      page: 0,
      limit: 0,
      id: "",
    };
    dispatch(getPurchaseCompanyDataRequest(payload));
  }, []);

  const onSubmitHandler = (formData) => {
    
    console.log(settingTz, "settingTz");
    formData.purchase_company_id = Number(settingTz[0]?.id);
    formData.order_bal = (parseFloat(invoiceTotal)-formData?.pay_amt)?.toString();
    formData.payment_type =
      formData.payment_type && Number(formData.payment_type[0]?.id);
    formData.payment_date = dayjs();
    formData.invoice_ids = ids;
    if (!formData.payment_type) {
      setError({
        payment_type: true,
      });
  
    }
    if(formData.payment_type === undefined){
      formData.payment_type=0
    }
    console.log(formData, "formData");
    // return
    dispatch(postPurchasePaymentDataRequest(formData));
  
  
  };

  useEffect(() => {
    let sum = 0;
    let selected_datas = filteredPayment?.data?.filter((items) =>
      ids?.some((isData) => isData == items.id)
    );
    selected_datas?.forEach((num) => {
      if (num.payment_balance != 0) {
        sum +=
          parseFloat(num.payment_balance) -
          creditAmount(num) +
          debitAmount(num);
      } else {
        sum += parseFloat(num.total);
      }
    });
    setTotal(sum);
  }, [ids]);

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  useEffect(() => {
    if (postPaymentSuccess) {
      toastr.clear();
      navigate("/payment/purchasepaymentlist");
      dispatch(poStateReset());
      toastr.success("Done");
      reset();
    }
  }, [postPaymentSuccess]);

  const handleChange = (event) => {
    setSettingTz(event);
    console.log(event[0]?.id, "on change");
    if (event[0]?.id != undefined) {
      dispatch(
        getPurchaseOrderDataRequest({
          query: "",
          page: 0,
          limit: 0,
          customerId: event[0]?.id,
          date_from: "",
          date_to: "",
          orderStatus: "",
        })
      );
      dispatch(
        getFilteredPaymentRequest({
          query: "",
          page: 0,
          limit: 0,
          customerId: event[0]?.id,
        })
      );
    }
  };

  const creditAmount = (row) => {
    let invoice_credits = _.filter(filteredPayment?.data, {
      invoice_main_id: row.id,
      credit_debit_type: 381,
    });
    console.log(row, invoice_credits, "invoicecreduts");
    let total_cred = 0;
    invoice_credits?.forEach((invoice) => {
      if (invoice.status != 8) {
        total_cred += parseFloat(invoice.total);
      }
    });
    return total_cred;
  };

  const debitAmount = (row) => {
    let invoice_credits = _.filter(filteredPayment?.data, {
      invoice_main_id: row.id,
      credit_debit_type: 383,
    });
    console.log(row, invoice_credits, "invoicecreduts");
    let total_cred = 0;
    invoice_credits?.forEach((invoice) => {
      if (invoice.status != 8) {
        total_cred += parseFloat(invoice.total);
      }
    });
    return total_cred;
  };

  const handleAmountValidation = (event) => {
    const { value } = event.target;
    const enteredAmount = parseFloat(value);
    if (enteredAmount > invoiceTotal) {
      toastr.warning("Amount Should be equal or lesser than total amount");
      setIsWarning(true);
      return;
    } else {
      setIsWarning(false);
    }
    console.log(total, filteredPayment?.data, invoiceTotal, "TOTAL DATA");
  };
  useEffect(() => {
    let sum = 0;
    filteredPayment?.data?.forEach((num) => {
      console.log(num, "toastlkd");

      if (num.status == 0 || num.status == 2) {
        sum += parseFloat(parseFloat(num.invoice_current_balance));
      }
    });
    console.log(sum, "toastlkd");
    setInvoiceTotal(sum);
  }, [filteredPayment?.data]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="page-content">
            <Container fluid>
              <div className="row">
                <div className="col-md-6">
                  <h4>
                    <Breadcrumb>
                      <BreadcrumbItem>Purchase Payment / New</BreadcrumbItem>
                    </Breadcrumb>
                  </h4>
                </div>
              </div>
              <Row>
                <Col xs={12}>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Card>
                      <CardBody>
                        <div>
                          <Row>
                            <Col md="4">
                              <div className="input-container">
                                <label for="payment_date">Company</label>
                                <Controller
                                  name="company_name"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      className="js-states form-control"
                                      placeholder={"Company"}
                                      options={companyData?.data || []}
                                      {...field}
                                      disabled={false}
                                      dropdownHandle={true}
                                      searchBy="company_name"
                                      // values={settingTz}
                                      searchable={true}
                                      labelField={"company_name"}
                                      valueField={"id"}
                                      name="company_name"
                                      onChange={handleChange}
                                    />
                                  )}
                                />
                              </div>
                              {errors?.customer_id?.message && (
                                <p className="error">
                                  {errors.customer_id.message}
                                </p>
                              )}
                            </Col>
                            <Col md="4">
                              <div className="input-container">
                                <label for="payment_date">Payment Type</label>
                                <Controller
                                  name="payment_type"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      className="js-states form-control"
                                      placeholder={"Payment Type"}
                                      options={paymentTypewithcheck || []}
                                      {...field}
                                      // defaultValue={company_details?.payment_type ? company_details?.payment_type : []}
                                      disabled={false}
                                      values={[{ id: 0, mode: "Cash" }]}
                                      searchable={true}
                                      searchBy="mode"
                                      labelField={"mode"}
                                      valueField={"id"}
                                      name="payment_type"
                                      // onChange={handlePaychange}
                                    />
                                  )}
                                />
                                {error?.payment_type && (
                                  <p className="text-danger">
                                    Payment Type is required
                                  </p>
                                )}
                              </div>
                            </Col>
                            <Col md="4">
                              <label for="payment_date">Payment Date</label>
                              <div className="input-container">
                                <input
                                  placeholder=" "
                                  type="date"
                                  defaultValue={dayjs().format("YYYY-MM-DD")}
                                  id="payment_date"
                                  // disabled
                                  name="payment_date"
                                  {...register("payment_date")}
                                  className="form-control"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <div className="input-container">
                                <label for="invoice_bal">Invoice Balance</label>
                                <input
                                  placeholder=" "
                                  type="number"
                                  value={invoiceTotal}
                                  disabled
                                  id="invoice_bal"
                                  name="invoice_bal"
                                  {...register("invoice_bal")}
                                  className="form-control"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="input-container mb-0">
                                <label for="pay_amt">Pay Amount</label>
                                <input
                                  placeholder=" "
                                  type="text"
                                  // defaultValue={company_details?.pay_amt}
                                  id="pay_amt"
                                  name="pay_amt"
                                  {...register("pay_amt")}
                                  className="form-control"
                                  onChange={handleAmountValidation}
                                />
                              </div>
                              <span>
                                {errors?.pay_amt?.message && (
                                  <p className="text-danger mx-4">
                                    {errors?.pay_amt?.message}
                                  </p>
                                )}
                              </span>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex  justify-content-end">
                          <div className="label label-cont">
                            <label htmlFor="pay_all">Pay All</label>
                            <div>
                              <input
                                type="checkbox"
                                disabled={!filteredPayment?.data?.length > 0}
                                checked={
                                  ids != 0
                                    ? ids.length ===
                                      filteredPayment?.data?.length
                                    : false
                                }
                                name="pay_all"
                                id="pay_all"
                                onChange={(e) => {
                                  let id = [];
                                  if (e.target.checked) {
                                    filteredPayment?.data?.forEach(
                                      (element) => {
                                        id.push(element?.id);
                                      }
                                    );
                                  }
                                  setIds(id);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive react-table">
                          <TableContainer
                            component={Paper}
                            style={{ minHeight: "300px" }}
                          >
                            <Table sx={{ minWidth: 700 }} stickyHeader>
                              <TableHead className="table-light table-nowrap">
                                <TableRow>
                                  <StyledTableCell align="">
                                    <TableSortLabel
                                      active={
                                        sortBy == "invoice_no" ? true : false
                                      }
                                      direction={order}
                                      className="table-header-column-name"
                                      onClick={(e) => {
                                        sortByKey("invoice_no");
                                      }}
                                    >
                                      Order No
                                    </TableSortLabel>
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    <TableSortLabel
                                      active={sortBy == "date" ? true : false}
                                      direction={order}
                                      className="table-header-column-name"
                                      onClick={(e) => {
                                        sortByKey("date");
                                      }}
                                    >
                                      Date
                                    </TableSortLabel>
                                  </StyledTableCell>

                                  <StyledTableCell align="">
                                    <TableSortLabel
                                      active={
                                        sortBy == "amount_due" ? true : false
                                      }
                                      direction={order}
                                      className="table-header-column-name"
                                      onClick={(e) => {
                                        sortByKey("amount_due");
                                      }}
                                    >
                                      Order Total <br /> Amount
                                    </TableSortLabel>
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    <TableSortLabel
                                      active={
                                        sortBy == "amount_received"
                                          ? true
                                          : false
                                      }
                                      direction={order}
                                      className="table-header-column-name"
                                      onClick={(e) => {
                                        sortByKey("amount_received");
                                      }}
                                    >
                                      Balance
                                    </TableSortLabel>
                                  </StyledTableCell>
                                  <StyledTableCell align="">
                                    Pay
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredPayment?.data?.length > 0 &&
                                  filteredPayment?.data?.map(
                                    (row, index) =>
                                      (row?.status == 0 ||
                                        row.status == 1 ||
                                        row.order_status == 2) && (
                                        <StyledTableRow>
                                          <StyledTableCell align="">
                                            {row?.id}
                                          </StyledTableCell>
                                          <StyledTableCell align="">
                                            {row.created_at &&
                                              dayjs(row.created_at).format(
                                                "YYYY-MM-DD"
                                              )}
                                          </StyledTableCell>

                                          <StyledTableCell align="">
                                            {row?.bill_amount}
                                          </StyledTableCell>
                                          <StyledTableCell align="">
                                            {row?.invoice_current_balance}
                                          </StyledTableCell>

                                          <StyledTableCell
                                            align=""
                                            className="button-items"
                                          >
                                            <div>
                                              <label
                                                htmlFor={`pay${row?.id}`}
                                              ></label>
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  name={`pay${row?.id}`}
                                                  id={`pay${row?.id}`}
                                                  checked={ids.includes(row.id)}
                                                  onChange={(e) =>
                                                    e.target.checked
                                                      ? setIds((prev) => [
                                                          ...prev,
                                                          row.id,
                                                        ])
                                                      : setIds(
                                                          ids?.filter(
                                                            (items) =>
                                                              items != row?.id
                                                          )
                                                        )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      )
                                  )}
                              </TableBody>
                            </Table>
                            {total > 0 ? (
                              <>
                                {" "}
                                <div className="text-end">
                                  <h6>
                                    <b>Selected Amount : {total}</b>
                                  </h6>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableContainer>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="">
                      <CardBody>
                        <div className="invoice-width">
                          <div class="input-container w-100">
                            <textarea
                              id="net_amount"
                              rows={3}
                              name="notes"
                              className="form-control"
                              {...register("notes")}
                            />
                            <label for="notes">Notes</label>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="text-end">
                      <Button
                        color="primary"
                        disabled={isWarning}
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        {/* <Footer /> */}
      </main>
    </>
  );
}
export default PurchasePaymentAdd;
