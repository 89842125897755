import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import brand2 from "../../assets/imgs/card-brands/2.png";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  generateEPDFRequest,
  generatePDFRequest,
  getUserCotationDetailsRequest,
  getUserOrderDataRequest,
  orderDetailGetRequest,
  orderStateResetRequest,
  UpdateOrderStatusRequest,
} from "../../store/order/order.action";
import { useEffect, useState } from "react";
import { orderSelector } from "../../store/order/order.model";
import { API_BASE, assestURL } from "../../services/config";
import dayjs from "dayjs";
import { statusClasses, statusData, TaxPercentage } from "../../utils/Constants";
import noImg from "../../assets/imgs/no-image.jpg";
import { toast } from "react-toastify";

function PageQuotationdetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isType = localStorage.getItem("type");
  /* Selectors */
  const {
    orderDetailData,
    updateStatusSuccess,
    pdfSendsuccess,
    getpdfDataSuccess,
    pdfLoad,
    getEpdfDataSuccess,
    pdfESendsuccess,
    pdfELoad,
    quotationDetails
  } = useSelector(orderSelector);
  console.log(pdfSendsuccess, getpdfDataSuccess, "45678ss9jl");
  console.log(quotationDetails?.user?.name, "78s");
  /* State */
  const [PDF, setPDF] = useState();
  const [pdfstatus, setpdfstatus] = useState({ display: "none" });
  /* Fucntion */
  const onStatusChanges = (eve, id) => {
    console.log(eve, id, "evvid");
    const payload = {
      id,
      status: eve,
    };
    dispatch(UpdateOrderStatusRequest(payload));
  };
  /* UseEffect */
  useEffect(() => {
    if (id !== 0) {
      const payload = {
        id: id,
      };

      dispatch(getUserCotationDetailsRequest(id));
    }
    if (pdfLoad || pdfELoad) {
      const payload = {
        id: id,
      };

      dispatch(orderDetailGetRequest(payload));
    }
  }, [id, pdfLoad, pdfELoad]);
  // useEffect(() => {
  //   if (pdfLoad) {
  //     toast.dismiss();
  //     toast.warning("PDF loading please wait");
  //   }
  //   if (pdfELoad) {
  //     toast.dismiss();
  //     toast.warning("PDF loading please wait");
  //   }
  //   if (updateStatusSuccess) {
  //     const payload = {
  //       id: id,
  //     };
  //     dispatch(orderDetailGetRequest(payload));
  //   }

  //   if (getpdfDataSuccess) {
  //     window.open(`${assestURL}${pdfSendsuccess}`, "_blank", "noreferrer");
  //     dispatch(orderStateResetRequest());
  //   }
  //   if (getEpdfDataSuccess) {
  //     window.open(`${assestURL}${pdfESendsuccess}`, "_blank", "noreferrer");
  //     dispatch(orderStateResetRequest());
  //   }
  // }, [
  //   updateStatusSuccess,
  //   pdfSendsuccess,
  //   getEpdfDataSuccess,
  //   pdfLoad,
  //   pdfELoad,
  // ]);
  console.log(assestURL, "assert");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Quotation detail</h2>
              <p>Details for Quoation ID: {quotationDetails?.data[0]?.id}</p>
            </div>
          </div>
          <div className="card">
            <header className="card-header">
              <Row className="align-items-center">
                <Col lg={6} md={6} className="mb-lg-0 mb-15">
                  <span>
                    <i className="material-icons md-calendar_today"></i>
                    <b>
                      {" "}
                      {dayjs(quotationDetails?.data[0]?.created_at).format(
                        "ddd, MMM D, YYYY, h:mma"
                      )}
                    </b>
                  </span>
                  <br />
                  <small className="text-muted">
                    Quotation Number: {quotationDetails?.data[0]?.cotation_no}
                  </small>
                  <br />
                  {/* <p>
                    {" "}
                    Quotation Status:{" "}
                    <span
                      className={`badge rounded-pill ${
                        statusClasses[quotationDetails?.status] ||
                        "alert-secondary"
                      }`}
                    >
                      {statusData[quotationDetails?.status]}
                    </span>
                  </p> */}
                </Col>
                {/* <Col lg={6} md={6} className=" text-md-end">
                  <div className="row">
                    <div className="col-6">
                      <div className="col-12 text-center mb-2">
                        {" "}
                        <label className="">Manage Order Status</label>
                      </div>
                      <div className="col-12 text-center">
                        <select
                          className="form-select d-inline-block mb-lg-0 mb-15 mw-200 "
                          onClick={(e) =>
                            onStatusChanges(
                              e.target.value,
                              orderDetailData[0]?.id
                            )
                          }
                        >
                          <option className="option" value={""}>
                            SELECT
                          </option>
                          <option className="option" value={0}>
                            ORDER INITIATED
                          </option>
                          <option className="option" value={1}>
                            ONHOLD
                          </option>
                          <option className="option" value={2}>
                            DISPATCHED
                          </option>
                          <option className="option" value={3}>
                            DELIVERY IN PROCESS
                          </option>
                          <option className="option" value={4}>
                            DELIVERED
                          </option>
                          <option className="option" value={5}>
                            COMPLETED
                          </option>
                          <option className="option" value={6}>
                            PAID
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-4 ">
               
                      <div className="col-12 text-center mb-2">
                        {" "}
                        <label className=""> Order Type</label>
                      </div>
                      <div className="col-12  text-center">
                        {" "}
                        <select
                          className="form-select d-inline-block  "
                          onClick={(e) => {
                            console.log(e.target.value, "hgkfhgid");
                            setPDF(e.target.value);
                          }}
                        >
                          <option className="option me-2  " value={""}>
                            SELECT
                          </option>
                          <option className="option" value={1}>
                            ESTIMATE
                          </option>
                          <option className="option" value={2}>
                            INVOICE
                          </option>
                        </select>
                        <p className="text-danger" style={pdfstatus}>
                          Click the Order Status to Print the PDF
                        </p>
                      </div>
                    </div>
                    <div className="col-2 ">
                      <div className="col-12  mb-2 ">
                        {" "}
                     
                      </div>
                      <div className="col-12 mt-4">
                        {" "}
                        <a
                          className="btn btn-secondary print ms-2"
                          href={null}
                          onClick={() => {
                            if (PDF) {
                              setpdfstatus({ display: "none" });
                              PDF == 1
                                ? dispatch(
                                    generateEPDFRequest(orderDetailData[0]?.id)
                                  )
                                : dispatch(
                                    generatePDFRequest(orderDetailData[0]?.id)
                                  );
                            } else {
                              setpdfstatus({ display: "block" });
                            }
                          }}
                        >
                          <i className="icon material-icons md-print"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </header>
            <div className="card-body">
              <Row className="mb-50 mt-20 order-info-wrap">
                <Col md={4}>
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle bg-primary-light">
                      <i className="text-primary material-icons md-person"></i>
                    </span>
                    <div className="text">
                      <h6 className="mb-1">Customer</h6>
                      <p className="mb-1">
                        {quotationDetails?.user?.name}
                        <br /> {quotationDetails?.user?.email}
                        <br /> {quotationDetails?.user?.mobile}
                      </p>
                      <Link to="/pagesellerslist">
                        <a href="">View profile</a>
                      </Link>
                    </div>
                  </article>
                </Col>

                <Col md={6}>
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle bg-primary-light">
                      <i className="text-primary material-icons md-place"></i>
                    </span>
                    <div className="text">
                      <h6 className="mb-1">Deliver to</h6>
                      <p className="mb-1">
                        City:
                        {quotationDetails?.user?.city == null
                          ? quotationDetails?.user?.city
                          : "-"}
                        <br />
                        Address:
                        {quotationDetails?.user?.city == null
                          ? quotationDetails?.user?.address
                          : "-"}
                      </p>
                    </div>
                  </article>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="40%">Product</th>
                          <th width="15%">Unit Price</th>
                          <th width="15%">Quantity</th>
                          <th width="15%">Transport Charges</th>
                          <th width="15%">Stock Available</th>

                          <th className="text-end" width="20%">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                       
                        {console.log(quotationDetails,'h5d')
                        }
                        {quotationDetails?.data[0]?.cotationdetails?.map((prod) => {
                          console.log(prod,'jdhgksj');
                          
                          return (
                            <tr>
                              <td>
                                <a className="itemside" href="#">
                                  <div className="left">
                                  {Array.isArray(prod?.products?.image) ? (
                                        prod?.products?.image[0] != [] &&
                                        prod?.products?.image[0] !=
                                          undefined ? (
                                          <img
                                            className="img-xs"
                                            //   src={item1}
                                            src={
                                              API_BASE +
                                              prod?.products?.image[0]
                                            }
                                            alt="Item"
                                            width="40"
                                            height="40"
                                          />
                                        ) : (
                                          <img
                                            className="img-xs"
                                            //   src={item1}
                                            src={noImg}
                                            alt="Item"
                                            width="40"
                                            height="40"
                                          />
                                        )
                                      ) : (
                                        <img
                                          className="img-xs"
                                          //   src={item1}
                                          src={noImg}
                                          alt="Item"
                                          width="40"
                                          height="40"
                                        />
                                      )}
                                  
                                    
                                  </div>
                                  <div className="info">
                                    {" "}
                                    {prod?.products?.name}
                                  </div>
                                </a>
                              </td>
                              <td>
                                  {" "}
                                {prod?.sale_price}
                                </td>
                                <td> {prod?.quantity}</td>
                                <td> {quotationDetails?.data[0]?.transport_charge ||0 }</td>
                                <td>
                                  {prod?.products?.product_inventory &&
                                  prod?.products?.product_inventory[0]
                                    ?.total_qty > 0
                                    ? prod?.products?.product_inventory[0]
                                        ?.total_qty
                                    : "0"}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {(prod?.quantity *prod?.sale_price).toFixed(2)}
                                </td>
                              </tr>
                            );
                          
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* <Link to="/pageorders">
                    <button className="btn btn-primary">
                      View Order Tracking
                    </button>
                  </Link> */}
                </Col>
                <Col lg={1}></Col>
                {/* <Col lg={4}>
                  <div className="box shadow-sm bg-light">
                    <h6 className="mb-15">Payment info</h6>
                    <p>
                      <img className="border" src={brand2} height="20" /> Master
                      Card **** **** 4768
                      <br /> Business name: Grand Market LLC
                      <br /> Phone: +1 (800) 555-154-52
                    </p>
                  </div>
                  <div className="h-25 pt-4">
                    <div className="mb-3">
                      <label>Notes</label>
                      <textarea
                        className="form-control"
                        id="notes"
                        name="notes"
                        placeholder="Type some note"
                      ></textarea>
                    </div>
                    <button className="btn btn-primary">Save note</button>
                  </div>
                </Col> */}
              </Row>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default PageQuotationdetails;
