import * as Yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const signupValidator = Yup.object().shape({
  name:Yup.string().required("Name is Required"),
  email: Yup.string().required("Mail is Required"),
  password: Yup.string()
    .matches(passwordRules, {
      message:
        "Please create a stronger password(min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit)",
    })
    .required("Required"),
});
export const signinValidator = Yup.object().shape({
  email: Yup.string().required("Mail is Required"),
  password: Yup.string().required("Required"),
});
export const AddGroupValidator = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
});

export const LinkedInValidator = Yup.object().shape({
  social_access_token: Yup.string().required("AccessToken is Required"),
  provided_user_id: Yup.string().required("Organisation Id is Required"),
});

export const profileSettingValidator = Yup.object().shape({
  company_name: Yup.string().required("Company Name is Required"),
  contact_number: Yup.string().required("Contact Number is Required"),
  mail_id: Yup.string().required("Mail id is Required"),
});

export const AccountSettingValidator = Yup.object().shape({
  group_id: Yup.number().required("Group is Required"),
});
export const CategoryAddListValiator = Yup.object().shape({
  name: Yup.string().required("Category Name  Required"),
  description: Yup.string().required("Category Desctiption Required"),
});

export const BrandAddListValiator = Yup.object().shape({
  name: Yup.string().required("Category Name  Required"),
  description: Yup.string().required("Category Desctiption Required"),
});
export const ProductAddListValiator = Yup.object().shape({
  name: Yup.string().required("Product Name  Required"),
  // unit_type:Yup.number().required("Grams Required"),
  description: Yup.string().required("Prodcut Desctiption Required"),
  // reg_price: Yup.string().required("Regular Price Required"),
  // sale_price: Yup.string().required("Sale Price Required"),
  // featured: Yup.string().required("Featured Required"),
  // catgory_id:Yup.number().required("Select Category"),
  // brand_id:Yup.number().required("Select Brand"),
   hsn_code:Yup.string().nullable(),
   rack:Yup.string().nullable(),
   msrp:Yup.string().required("MRP is Required"),
   p_rate:Yup.string().required("Purchase rate is Required"),
   w_rate:Yup.string().required("Purchase rate is Required"),
   c_rate:Yup.string().required("Purchase rate is Required"),
   tax:Yup.string().required("Tax is Required"),
   cat:Yup.string().required("Price Catagery is Required"),
});
export const InventryCreateValidator=Yup.object().shape({
  product_id:Yup.number().required("Select Product"),
  // opening_qty: Yup.number().required("Opening Quantity Required"),
  current_qty: Yup.number().required("Current Quantity Required"),
  inward_qty: Yup.number().nullable(),
  // unit_type:Yup.number().required("Grams Required"),
  // outward_qty: Yup.number().notRequired()
})  
export const InventryCreateValidator2=Yup.object().shape({
  // product_id:Yup.number().required("Select Product"),
  // opening_qty: Yup.number().required("Opening Quantity Required"),
  current_qty_from: Yup.string().nullable().notRequired(),
  current_qty_to: Yup.string().nullable(),
  order_status: Yup.string(),
  // unit_type:Yup.number().required("Grams Required"),
  // outward_qty: Yup.number().notRequired()
})  
export const VendorAddValidator = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  contact_person: Yup.string().required("Contact Person is required"),
  vendor_type: Yup.number().required("Vendor Type is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
    gst_no:Yup.string().nullable(),
    city:Yup.string().nullable(),
    zipcode:Yup.string().nullable(),
    address:Yup.string().nullable(),
    area:Yup.string().nullable(),

  // password: Yup.string()
  //   .min(8, "Password must be at least 8 characters long")
  //   .required("Password is required"),
  // first_name: Yup.string(),
  // last_name: Yup.string(),
  // dob: Yup.date().notRequired(),
  // gender: Yup.string().oneOf(['Male', 'Female', 'Other'], "Invalid Gender"),
  // address: Yup.string(),
  // city: Yup.string(),
  // zipcode: Yup.string().matches(/^[0-9]{5,6}$/, "Zipcode must be 5 or 6 digits"),
});

export const UsersAddValidator = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  contact_person: Yup.string().required("Contact Person is required"),
  role_id: Yup.number().required("Vendor Type is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
    gst_no:Yup.string().nullable(),
    city:Yup.string().nullable(),
    zipcode:Yup.string().nullable(),
    address:Yup.string().nullable(),
    area:Yup.string().nullable(),


})

