import React, { useEffect } from "react";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import {
  creditDueDataRequest,
  sreditDueDataRequest,
  weeklyPaymentReminderDataRequest,
} from "../../store/order/order.action";
import { orderSelector } from "../../store/order/order.model";

export const WeeklyPayReminder = () => {
  const dispatch = useDispatch();
  const { creditDueData, weeklypayLoadData } = useSelector(orderSelector);
  console.log(weeklypayLoadData, "weeklypayLoadData");
  /* useEffect */
  useEffect(() => {
    dispatch(weeklyPaymentReminderDataRequest({}));
  }, []);
  return (
    <div>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">
                Weekly Payment Reminder
              </h2>
              <p>Important insights of your business here</p>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <h4 className="card-title">Vendor Detail</h4>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="align-middle" scope="col">
                          Vendor Name
                        </th>
                        <th className="align-middle" scope="col">
                          Last Payment Data
                        </th>
                        <th className="align-middle" scope="col">
                          Last Paid Amount
                        </th>

                        <th className="align-middle" scope="col">
                          Last Purchase Date
                        </th>
                        <th className="align-middle" scope="col">
                          Total Balance Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {weeklypayLoadData?.map((ele, indx) =>
                        Array.isArray(ele?.orders) && ele.orders.length > 0 ? (
                          ele.orders.map((ele1) => (
                            <tr key={`${indx}-${ele1?.user?.id}`}>
                              <td>
                                <a className="fw-bold" href="#">
                                  {ele1?.name}
                                </a>
                              </td>
                              <td>{ele1?.last_paid_date}</td>
                              <td>{ele1?.user?.duedays_limit}</td>
                              <td>{ele1?.orderId}</td>
                              <td>{ele1?.subTotal}</td>
                              <td>
                                {dayjs(ele1?.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>{ele1?.daysExceeded}</td>
                            </tr>
                          ))
                        ) : (
                          <tr key={`${indx}-empty`}>
                            <td colSpan="7"></td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
