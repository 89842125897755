import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useForm } from "react-hook-form";
import { DateRangePicker } from "rsuite";
import Select from "react-dropdown-select";
import { getUserDataRequest, userSelector } from "../../store/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { createOrderPayload } from "../../utils/config";
import {
  getAllUserOrderDataRequest,
  getUserOrderDataRequest,
  orderStateResetRequest,
  UpdateOrderStatusSuccess,
} from "../../store/order/order.action";
import { orderSelector } from "../../store/order/order.model";
import { statusClasses, statusData } from "../../utils/Constants";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
function ProductReport() {
  const dispatch = useDispatch();
  // userOrderData
  const { userOrderData, userAllOrderData } = useSelector(orderSelector);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { afterToday } = DateRangePicker;
  const [value, setValues] = useState(null);
  const { userData } = useSelector(userSelector);
  console.log(userData, "iii");

  const { register, handleSubmit, setValue, reset } = useForm();
  //pagenation
  const totalPages1 = Math.ceil(userOrderData.count / rowsPerPage);
  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const filter = (formData) => {
    formData.from_date = value?.[0] ?? "";
    formData.to_date = value?.[1] ?? "";
    console.log(formData, "ooo");
    const payload = createOrderPayload(
      page,
      rowsPerPage,
      "",
      "",
      formData?.customer,
      formData?.order_status,
      formData?.order_id,
      formData?.from_date,
      formData?.to_date
    );
    dispatch(getUserOrderDataRequest(payload));

    const payload1 = createOrderPayload(
      0,
      0,
      "",
      "",
      formData?.customer,
      formData?.order_status,
      formData?.order_id,
      formData?.from_date,
      formData?.to_date
    );
    dispatch(getAllUserOrderDataRequest(payload1));
    reset();
    setValues(null);
  };

  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = (event) => {
  //     const workbook = XLSX.read(event.target.result, { type: 'binary' });
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const sheetData = XLSX.utils.sheet_to_json(sheet);

  //     setData(sheetData);
  //   };
  //   reader.readAsBinaryString(file);
  // };
  console.log(userAllOrderData, "qqq");
  const handleDownload = () => {
    const transformedData = userAllOrderData.map((order) => {
      const details = order.orderdetails[0];
      const product = details?.products;
      const taxableAmount = parseFloat(details?.sale_price) * parseInt(details?.quantity, 10);
      const taxPercentage = parseFloat(product?.tax) || 0;
      const cgst = (taxPercentage / 2 / 100) * taxableAmount;
      const sgst = (taxPercentage / 2 / 100) * taxableAmount;
      const igst = (taxPercentage / 100) * taxableAmount;
      const invoiceTotal = taxableAmount + cgst + sgst + igst;
  
      const creditDays = order?.user.duedays_limit || 0;
      const dueDate = new Date(order?.created_at);
      dueDate.setDate(dueDate.getDate() + creditDays);
  
      return {
        "Invoice No": order?.order_no,
        "Invoice Date": new Date(order?.created_at).toLocaleDateString(),
        "ACCOUNT Name": order?.user.name,
        "Customer Type": "Vendor",
        "Qty": details?.quantity,
        "Price": details?.sale_price,
        "Discount Value": order?.discount || 0,
        "Transport Chaarge":order?.transport_charge,
        "Taxable Amount": taxableAmount.toFixed(2),
        "CGST": cgst.toFixed(2),
        "SGST": sgst.toFixed(2),
        "IGST": igst.toFixed(2),
        "Invoice Total": invoiceTotal.toFixed(2),
        "Sales Representative": "Not Assigned", 
        "Credit Days": creditDays,
        "Due Date": dueDate.toLocaleDateString(),
        "Due Status":"",
        "Payment Method": order?.payment_method === "1" ? "Credit" : "Payment Gateway", 
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };
  useEffect(() => {
    const payload2 = {
      query: "",
      page: 1,
      limit: 10,
      userId: "",
    };
    dispatch(getUserDataRequest(payload2));
    dispatch(orderStateResetRequest());
  }, []);
  console.log(userData, "qqq");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Orders List Report</h2>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <form onSubmit={handleSubmit(filter)}>
                <div className="row">
                  <div className="col-4 mt-3">
                    <label className="form-label" htmlFor="order_id">
                      Order ID
                    </label>
                    <input
                      className="form-control"
                      id="order_id"
                      type="text"
                      placeholder="Type here"
                      {...register("order_id")}
                    />
                  </div>
                  <div className="col-4 mt-3">
                    <label className="form-label" htmlFor="order_customer">
                      Customer Name
                    </label>
                    <Select
                      placeholder="Customer"
                      className="form-control"
                      options={[
                        { id: "", name: "All" },
                        ...(Array.isArray(userData?.data)
                          ? userData?.data
                          : []),
                        ,
                      ]}
                      disabled={false}
                      dropdownHandle={true}
                      searchable={true}
                      searchBy="name"
                      labelField={"name"}
                      valueField={"id"}
                      name="name"
                      onChange={(selectedOption) =>
                        setValue("customer", selectedOption[0].id)
                      }
                      // value={selectedCustomer}
                      // onChange={handleCustomerChange}
                    />
                  </div>

                  <div className="col-4 mt-3">
                    <label className="form-label">Order Status</label>
                    <select
                      className="form-select"
                      {...register("order_status")}
                    >
                      <option className="option" value={""}>
                        All
                      </option>
                      <option className="option" value={0}>
                      ORDER INITIATED
                      </option>
                      <option className="option" value={1}>
                        ONHOLD
                      </option>
                      <option className="option" value={2}>
                        DISPATCHED
                      </option>
                      <option className="option" value={3}>
                        DELIVERY IN PROCESS
                      </option>
                      <option className="option" value={4}>
                        DELIVERED
                      </option>
                      <option className="option" value={5}>
                        COMPLETED
                      </option>
                      <option className="option" value={6}>
                        PAID
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 mt-3">
                    <label className="form-label" htmlFor="order_total">
                      Date Range
                    </label>
                    <DateRangePicker
                      className="d-flex"
                      showOneCalendar
                      shouldDisableDate={afterToday()}
                      value={value}
                      onChange={(newValue) => setValues(newValue)}
                      placeholder="Select Date Range"
                    />
                  </div>
                  <div className="col-2 lg-1 md-1 mt-3 d-flex align-items-end justify-content-center ">
                    <button
                      title="Filter Data"
                      className="btn btn-md rounded font-sm px-5"
                      type="submit"
                    >
                      Filter
                    </button>
                  </div>
                  <div className="col-2 mt-3 d-flex align-items-end">
                    <button
                      title="Export Excel"
                      className="btn btn-md rounded font-sm px-5"
                      type="button"
                      onClick={handleDownload}
                    >
                      Export
                    </button>
                  </div>
                </div>
              </form>
            </header>

            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Customer name</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userOrderData?.data?.map((order) => (
                     
                      <tr key={order?.orders?.id}>
                         {console.log(order?.status)
                         }
                        <td>{order?.id}</td>

                        <td>
                          <b>{order?.user?.name}</b>
                        </td>

                        <td>₹{parseFloat(order?.sub_total).toFixed(2)}</td>
                        <td>
                          <span
                            className={`badge rounded-pill ${
                              statusClasses[order?.status] || "alert-secondary"
                            }`}
                          >
                            {statusData[order?.status]}
                          </span>
                        </td>

                        <td>{dayjs(order?.created_at).format("DD.MM.YYYY")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination-area mt-15 mb-50">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="material-icons md-chevron_left"></i>
                      </a>
                    </li>

                    {pagesToDisplay.map((page) => {
                      return page != "..." ? (
                        <li
                          key={page}
                          className={`page-item b ${
                            currentPage === page ? "active" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </a>
                        </li>
                      ) : page == "..." ? (
                        <li
                          key={page}
                          className={`page-item ${
                            currentPage === page ? "active" : ""
                          }`}
                        >
                          <a className="page-link" href="#">
                            {page}
                          </a>
                        </li>
                      ) : (
                        ""
                      );
                    })}

                    <li
                      className={`page-item ${
                        currentPage === totalPages1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="material-icons md-chevron_right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default ProductReport;
