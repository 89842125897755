import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "react-bootstrap";
import classnames from "classnames";
import "../../App.css";
import { useEffect, useState, useMemo } from "react";
import Select from "react-dropdown-select";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import dayjs from "dayjs";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../../utils/pagination";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { addInvoice } from "../../utils/Validation";
import { useNavigate, useParams } from "react-router";
// import toastr from "toastr";
import { toast } from "react-toastify";
import _ from "loadsh";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { productSelector } from "../../store/product/product.model";
import { paymentType, TaxPercentage } from "../../utils/Constants";
import {
  getPurchaseOrderByIdDataRequest,
  poStateReset,
  postPurchaseOrderDataRequest,
  updatePurchaseOrderDataRequest,
} from "../../store/PurchaseOrder/po.action";
import { getProductRequest } from "../../store/product/product.action";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
import { getPurchaseCompanyDataRequest } from "../../store/PurchaseCompany/pc.action";
import { purchaseOrderSelector } from "../../store/PurchaseOrder/po.model";
import DeleteIcon from "@mui/icons-material/Delete";
import { getUserDataRequest, userSelector } from "../../store/user/user.action";
import {
  checkOutDataRequest,
  orderDetailGetRequest,
  orderStateResetRequest,
} from "../../store/order/order.action";
import { orderSelector } from "../../store/order/order.model";

const CreateOrder = () => {
  const { id } = useParams();
  console.log(id, "qqq");
  let userId = localStorage.getItem("id");
  const { getProductData } = useSelector(productSelector);
  //   const { companyData } = useSelector(companySelector);
  const { userData } = useSelector(userSelector);
  console.log(userData, "userData");

  const { succesMessage, paymentSuccess, orderDetailData } =
    useSelector(orderSelector);

  const { UpdateSuccess, PostSuccess, PodataById } = useSelector(
    purchaseOrderSelector
  );
  console.log(orderDetailData, "orderDetailData");

  console.log(succesMessage, paymentSuccess, "ssfdff");
  console.log(getProductData, PostSuccess, "getss");

  const [activeTab, setActiveTab] = useState("5");
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(addInvoice),
  });
  console.log(errors, "ERRORS");

  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("customer_name");
  const [activesortBy, setActiveSortBy] = useState("customer_name");
  const [page, setPage] = useState(0);
  const [payment, setPayment] = useState();
  console.log(payment, "payment");

  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [current_bal, setCurrentBal] = useState(0);
  const [invoiceDD, setinvoiceDD] = useState("");
  const [totalAmt, settotalAmt] = useState(0);
  const [isType, setIstype] = useState(0);
  const [total, settotal] = useState(0);
  const [discount_val, setDiscountVal] = useState("0");
  const [roundOffAmt, setroundOffAmt] = useState("0");
  const [vat_amount, setVatAmount] = useState(0);
  const [tran_amount, setTranAmount] = useState("0");
  const [freightCharge, setfreightCharge] = useState("0");
  const [label, setLabel] = useState("label");
  const [taxType, setTaxType] = useState(TaxPercentage);
  const [userIds, setUsrIds] = useState();
  let [purchaseDateVal, setpurchaseDateVal] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [voucherDetails, setVoucherDetails] = useState([
    {
      product_id: 0,
      category_id: 0,
      quantity: 0,
      unit: "0",
      sale_price: 0,
      reg_price: 0,
      amount: 0,
      tax: "",
    },
  ]);
  console.log(voucherDetails, "voucherDetails");

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  // useEffect(() => {
  //   console.log(voucherDetails, "voucherDetails1");
  // }, [voucherDetails]);
  // useEffect(() => {
  //   initHit();
  // }, []);
  const transport = (value) => {
    setTranAmount(value);
  
    // if (value > 0) {
    //   console.log(totalAmt, tran_amount, "aaa");
    //   let a = parseFloat(totalAmt) || 0;
    //   let b = parseFloat(value) || 0;
    //   let c = parseFloat(vat_amount) || 0;
    //   let tot = (a + b+c).toFixed(2);

    //   settotal(tot);
    // } else {
    //   settotal(total);
    // }
  };
  useEffect(()=>{
    updateTotals(voucherDetails)
  },[tran_amount])
  function allKeysExceptNarrationHaveValues(obj) {
    const keysToCheck = _.omit(obj, "narration", "id");
    console.log(
      keysToCheck,
      "allObjectsKeysExceptNarrationHaveValues(arrayOfObjects)"
    );
    return _.every(keysToCheck, (value) => !_.isEmpty(value));
  }

  function allObjectsKeysExceptNarrationHaveValues(array) {
    return _.every(array, (obj) => allKeysExceptNarrationHaveValues(obj));
  }

  const onSubmitHandler = (formData) => {
   
    let cart = voucherDetails.filter((ele) => {

      if (ele.quantity != "") {
        return ele;
      }
    });
    console.log(cart,"opopo");
  
    let datas = {
      total_price: total,
      transport_charge: tran_amount,
      sub_total: totalAmt,
      tax_price: vat_amount,
      type: payment,
      user_id: formData.user_id,
      cartDetails: {
        data: cart?.map((ele) => {
          let taxamt=ele?.tax?(ele?.quantity*((ele?.tax/100)*ele?.sale_price)).toFixed(2):null
          return {
            user_id: formData?.user_id,
            product_id: ele.product_id,
            category_id: ele.category_id,
            quantity: ele.quantity,
            reg_price: ele?.reg_price,
            sale_price: ele.sale_price,
            amount: ele.amount,
            tax:taxamt,
            tax_per:ele?.tax
          };
        }),
      },
    };
    console.log(datas, "senddatad");
    dispatch(checkOutDataRequest(datas));
  };

  const  handleKeyDown=(event) =>{
    console.log('jhkdhfkdhz');
    
    if (event.keyCode === 13 ) {
      event.preventDefault();
    }
  }
  const handleinvoiceDueDate = (event, type) => {
    let invoiceDueDate = "";
    let quotation_date = "";

    if (type == "invoice_date") {
      invoiceDueDate = event?.target?.value;
      quotation_date = purchaseDateVal;
      setinvoiceDD(invoiceDueDate);
    } else if (type == "quotation_date") {
      quotation_date = event?.target?.value;
      setpurchaseDateVal(quotation_date);
      invoiceDueDate = invoiceDD;
    }

    let date1 = new Date(invoiceDueDate);
    let date2 = new Date(quotation_date);

    let Difference_In_Time = date1.getTime() - date2.getTime();
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    if (Difference_In_Days < 0) {
      toast.clear();
      toast.error("Please choose the valid Date!");
      setinvoiceDD("");
      setValue("due_days", 0);
    } else setValue("due_days", invoiceDueDate != "" ? Difference_In_Days : 0);
  };
  const calculateAmount = (index) => {
    const data = [...voucherDetails];
    
    console.log(index, data, "index");
    if (index < 0 || index >= data.length) {
      console.error("Invalid index provided");
      return;
    }

    const qty = parseFloat(data[index].quantity) || 0;
    const unitPrice = parseFloat(data[index].sale_price) || 0;
    const baseAmount = qty * unitPrice;
    // const discountPercentage = parseFloat(data[index]?.discount_val || 0);
    // if (discountPercentage > baseAmount) {
    //   toastr.error("Discount value cannot be greater than the amount!");
    //   return;
    // }
    // const discountedAmount = Math.max(baseAmount - discountPercentage, 0);

    // const taxPercentage = data[index]?.tax === "VAT 15%" ? 15 : 0 ?? 0;
    
    const taxPercentage = (() => {
      switch (data[index].tax) {
        case "GST 5%":
          return 5;
        case "GST 12%":
          return 12;
        case "GST 18%":
          return 18;
        case "GST 28%":
          return 28;
        default:
          return 0;
      }
    })();
    console.log(taxPercentage, "index");
    // const taxAmount = (taxPercentage / 100) * baseAmount;
    const taxAmount = (data[index]?.tax / 100) * baseAmount;

   console.log(taxAmount,'taxAmount');
   
    const finalAmount =taxAmount? baseAmount + taxAmount:baseAmount;

    data[index] = {
      ...data[index],
      amount: finalAmount.toFixed(2),
    };
    console.log(data, "index");
    setVoucherDetails(data);
    updateTotals(data);
  };

  const updateTotals = (data) => {
    console.log(data,'zxcv');
    
    const totalAmt = data
      .reduce((sum, item) => {
        const qty = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.sale_price) || 0;
        return sum + qty * unitPrice;
      }, 0)
      .toFixed(2);

    const vatAmount = data
      .reduce((sum, item) => {
        console.log(item,'asdfg');
        
        // const discountPercentage = parseFloat(item?.discount_val || 0);
        const baseAmount =
          (parseFloat(item.quantity) || 0) * (parseFloat(item.sale_price) || 0);
        // const discountedAmount = baseAmount - discountPercentage;
        const tax=(item.tax/100)*baseAmount;
        console.log(tax,"iuyee");
        
        return sum+tax
        const taxPercentage = (() => {
          switch (item.tax) {
            case "GST 5%":
              return 5;
            case "GST 12%":
              return 12;
            case "GST 18%":
              return 18;
            case "GST 28%":
              return 28;
            default:
              return 0;
          }
        })();
        // return sum + (taxPercentage / 100) * baseAmount;
      }, 0)
      .toFixed(2);

    const netAmt = data
      .reduce((sum, item) => {
        const qty = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.sale_price) || 0;
        const baseAmount = qty * unitPrice;
        // const discountPercentage = parseFloat(item?.discount_val || 0);
        // const discountedAmount = baseAmount - discountPercentage;
        const taxPercentage = item?.tax === "VAT 15%" ? 15 : 0;
        return sum + (baseAmount + (taxPercentage / 100) * baseAmount);
      }, 0)
      .toFixed(2);
    // const discountTotal = data
    //   .reduce((sum, item) => {
    //     const discountPercentage = parseFloat(item?.discount_val || 0);

    //     return sum + discountPercentage;
    //   }, 0)
    //   .toFixed(2);
    console.log(totalAmt, "iii");
    console.log(vatAmount, "iii");
    console.log(parseFloat(vatAmount) + parseFloat(totalAmt), "iii");
    let Total = tran_amount>0? parseFloat(vatAmount) + parseFloat(totalAmt) + parseFloat(tran_amount):parseFloat(vatAmount) + parseFloat(totalAmt) ;
    settotalAmt(totalAmt);
    setVatAmount(vatAmount);
    // setDiscountVal(discountTotal);
    settotal(Total);
    setValue("net_amount", Total);
  };

  useEffect(() => {
    const payload = {
      query: "",
      prod_id: 0,
      page: "",
      limit: "",
    };

    dispatch(getProductRequest(payload));
    const payload1 = {
      query: "",
      page: 0,
      limit: 0,
      userId: "",
    };
    dispatch(getUserDataRequest(payload1));
    // dispatch(poStateReset());
  }, [searchQuery, currentPage, rowsPerPage]);
  useEffect(() => {
    if (PostSuccess || UpdateSuccess) {
      navigate("/purchanseInovice");
      dispatch(poStateReset());
    }
  }, [PostSuccess, UpdateSuccess]);
  useEffect(() => {
    if (succesMessage == "Payment Created" || paymentSuccess == "Success") {
      toast.dismiss();
      toast.success("Order Created Successfully");
      navigate("/pageorders");
      dispatch(orderStateResetRequest());
    }
  }, [succesMessage, paymentSuccess]);

  useEffect(() => {
    if (orderDetailData) {
      console.log(orderDetailData, orderDetailData[0]?.payment_method, "poiuy");
      setValue("user_id", orderDetailData[0]?.user.id);
      orderDetailData[0]?.payment_method === "1"
        ? setPayment(1)
        : setPayment(0);

      orderDetailData[0]?.orderdetails?.map((ele) => {
        console.log(ele, "vuu");

        let arr = [...voucherDetails];
        arr = [
          {
            product_id: ele.product_id,
            category_id: ele?.orderdetails?.category_id,
            quantity: ele.quantity,
            unit: ele.unit_type,
            sale_price: ele.sale_price,
            reg_price: ele.reg_price,
            amount: orderDetailData[0]?.total_price,
            tax: ele.tax,
          },
          ...arr,
        ];
        setVoucherDetails(arr);
        console.log(arr, "vuu");
      });
    } else {
      setValue("user_id", "");
      setVoucherDetails({
        product_id: 0,
        category_id: 0,
        quantity: 0,
        unit: "0",
        sale_price: 0,
        reg_price: 0,
        amount: 0,
        tax: "",
      });
    }
  }, [orderDetailData]);
  console.log(orderDetailData, "voo");
  useEffect(() => {
    if (id) {
      const payload = {
        id: id,
      };

      dispatch(orderDetailGetRequest(payload));
    } else {
      dispatch(orderStateResetRequest());
      setValue("user_id", "");
      setVoucherDetails([
        {
          product_id: 0,
          category_id: 0,
          quantity: 0,
          unit: "0",
          sale_price: 0,
          reg_price: 0,
          amount: 0,
          tax: "",
        },
      ]);
    }
  }, [id]);

  useEffect(() => {
    console.log("Voucherrr after", voucherDetails);
  }, [voucherDetails]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap overflow-visible">
        <Header />
        <section className="content-main ">
          <div className="page-content">
            <div className="d-flex justify-content-between">
              <h2 className="content-title mb-3">CreateOrder</h2>
              {/* <h4 className="page-title"></h4> */}
              <h5>
                Bill Amount : {total}
                {/* {getValues("net_amount")} */}
              </h5>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)}
            
          
            >
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    {/* <Col md="4">
                      <div className="mb-3">
                        <div class="input-container w-100">
                          <label for="invoice_no">Invoice No</label>
                          <input
                            placeholder=" "
                            type="text"
                            id="invoice_no"
                            name="invoice_no"
                            className=" w-100 p-1 fs-5"
                            style={{
                              border: "none",
                              borderBottom: "1px solid",
                            }}
                            {...register("invoice_no")}
                          />
                        </div>
                      </div>
                    </Col> */}
                    <Col md="4 overflow-visible">
                      <div className="input-container ">
                        <label htmlFor="vendor_id">Select Vendor</label>
                        <Controller
                          name="vendor_id"
                          control={control}
                          render={({ field, ref }) => (
                            <Select
                              className="js-states form-control"
                              options={userData?.data || []}
                              placeholder="Select Vendor Name"
                              {...field}
                              ref={ref}
                              disabled={false}
                              dropdownHandle={true}
                              searchable={true}
                              searchBy="name"
                              labelField={"name"}
                              valueField={"id"}
                              name="name"
                              values={
                                getValues("user_id")
                                  ? userData?.data?.filter((ele) => {
                                      console.log(ele.id, "qwqw");
                                      return getValues("user_id") == ele.id;
                                    })
                                  : []
                              }
                              onChange={(value) => {
                                setValue("user_id", value[0]?.id);
                                setIstype(value[0]?.vendor_type);
                                console.log(value[0]?.vendor_type);
                                // setUsrIds(value[0].id);
                                // let data = [...voucherDetails];
                                // data.map((ele) => {
                                //   ele.user_id = value[0].id;
                                // });
                                // setVoucherDetails(data);
                              }}
                            />
                          )}
                          {...register("user_id")}
                        />
                      </div>
                      {errors?.user_id?.message && (
                        <p className="error">{errors.user_id.message}</p>
                      )}
                    </Col>

                    <Col md="4">
                      <div className="input-container ">
                        <label for="quotation_date">Payment Type</label>

                        <Controller
                          name="type"
                          control={control}
                          render={({ field }) => (
                            <Select
                              className="js-states form-control"
                              placeholder={"Pay Mode"}
                              options={paymentType}
                              {...field}
                              value={paymentType.find(
                                (option) => option.id === parseInt(payment)
                              )}
                              disabled={false}
                              dropdownHandle={true}
                              labelField="mode"
                              searchable={true}
                              valueField={"id"}
                              name="type"
                              onChange={(e) => {
                                console.log(e[0].id, "uuu");

                                setPayment(e[0].id);
                              }}
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="d-flex align-items-end">
                        <div class="input-container ">
                          <label for="quotation_date">Purchase Date</label>
                          <input
                            placeholder=" "
                            className="w-100 p-1 form-control"
                            type="date"
                            defaultValue={dayjs().format("YYYY-MM-DD")}
                            id="quotation_date"
                            onChange={(e) =>
                              handleinvoiceDueDate(e, "quotation_date")
                            }
                            name="quotation_date"
                            value={purchaseDateVal}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* </CardBody>
              </Card>
              <Card className="overflow-visible">
                <CardBody> */}
                  <div className="table-responsive react-table overflow-visbile">
                    <TableContainer
                      component={Paper}
                      style={{ minHeight: "300px" }}
                    >
                      <Table sx={{ minWidth: 700 }} stickyHeader>
                        <TableHead className="table-light table-nowrap">
                          <TableRow>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "product_desc" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("product_desc");
                                }}
                              >
                                Product Description
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "unit" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("unit");
                                }}
                              >
                                Qty
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "price" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("price");
                                }}
                              >
                                Price
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">Tax</StyledTableCell>

                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "amount" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("amount");
                                }}
                              >
                                Amount
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">Action</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {voucherDetails?.map((row, index) => {
                            return (
                              <StyledTableRow>
                                <StyledTableCell align="">
                                  {/* <Controller
                                    name="product_id"
                                    control={control}
                                    render={({ field, ref }) => (
                                      <Select
                                        className="js-states select-control"
                                        options={getProductData.data ?? []}
                                        {...field}
                                        ref={ref}
                                        disabled={false}
                                        dropdownHandle={true}
                                        searchable={true}
                                        labelField={"description"}
                                        searchBy="description"
                                        valueField={"id"}
                                        name="product_id"
                                        values={
                                          getProductData?.data?.filter(
                                            (ele) =>
                                              ele.id ==
                                              voucherDetails[index].product_id
                                          ) ?? []
                                        }
                                        onChange={(value) => {
                                          let data = voucherDetails;
                                          console.log(value, "VOUCHERDETAILS1");
                                          data[index].product_id = value[0]?.id;
                                          data[index].unit = 1;
                                          data[index].sale_price =
                                            value[0].w_rate;
                                          setVoucherDetails([
                                            ...data,
                                            {
                                              additional_description: "",
                                              quantity: 0,
                                              unit: "0",
                                              sale_price: 0,
                                              amount: 0,
                                              id: index + 1,
                                            },
                                          ]);
                                          
                                        }}
                                      />
                                    )}
                                  /> */}
                                  <Controller
                                    name="product_id"
                                    control={control}
                                    render={({ field, ref }) => (
                                      <Select
                                        className="js-states form-control"
                                        options={getProductData.data ?? []}
                                        {...field}
                                        ref={ref}
                                        disabled={false}
                                        dropdownHandle={true}
                                        searchable={true}
                                        labelField={"description"}
                                        searchBy="description"
                                        valueField={"id"}
                                        name="product_id"
                                        values={
                                          getProductData?.data?.filter(
                                            (ele) =>
                                              ele.id ===
                                              voucherDetails[index].product_id
                                          ) ?? []
                                        }
                                        onChange={(value) => {
                                          let data = [...voucherDetails];
                                          console.log(value[0]?.tax,'hyhy');
                                          
                                          if (value.length > 0) {
                                           
                                            const texs=TaxPercentage.find((ele)=>ele.value ==value[0]?.tax)
                                            console.log(texs?.percent,'ttttt');
                                            const selectedProduct = value[0];
                                            data[index].product_id =
                                              selectedProduct.id;
                                            data[index].sale_price =
                                              isType == 0
                                                ? selectedProduct?.msrp
                                                : isType == 1
                                                ? selectedProduct?.w_rate
                                                : selectedProduct?.c_rate;
                                            data[index].reg_price =
                                              selectedProduct.msrp;
                                            data[index].category_id =
                                              selectedProduct.catgory_id;
                                              
                                              data[index].tax= texs?.percent
                                              
                                            calculateAmount(index);
                                            if (index === data.length - 1) {
                                              data.push({
                                                // user_id: userIds,
                                                product_id: 0,
                                                category_id: 0,
                                                quantity: 0,
                                                unit: "0",
                                                sale_price: 0,
                                                reg_price: 0,
                                                amount: 0,
                                                tax: "",
                                                // discount_val: 0,
                                                // id: index + 1,
                                              });
                                            }
                                          }

                                          setVoucherDetails(data);
                                        }}
                                      />
                                    )}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      value={row?.quantity}
                                      id={`narration${index}`}
                                      className=" form-control"
                                      name={`narration${index}`}
                                      onKeyDown={handleKeyDown}
                                      //   onChange={(e) => {
                                      //     const { value } = e.target;
                                      //     let data = voucherDetails;
                                      //     data[index].quantity = value;
                                      //     const pro_amt = data[index].sale_price;
                                      //     const pro_amount = parseFloat(
                                      //       value * pro_amt
                                      //     );
                                      //     data[index].amount =
                                      //       pro_amount.toFixed(2);
                                      //     setVoucherDetails([...data]);
                                      //   }}
                                      // value={voucherDetails[index]?.quantity || ""}
                                      onChange={(e) => {
                                        let data = [...voucherDetails];
                                        data[index].quantity = e.target.value;
                                        setVoucherDetails(data);
                                        calculateAmount(index);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>

                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">

                                    <input
                                      placeholder=" "
                                      type="text"
                                      id={`narration${index}`}
                                      name={`narration${index}`}
                                      className=" form-control"
                                      value={row?.sale_price}
                                      onKeyDown={handleKeyDown}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].sale_price = value;
                                        const qty = data[index].quantity;
                                        const pro_amount = parseFloat(
                                          value * qty
                                        );
                                        data[index].amount =
                                          pro_amount.toFixed(2);
                                        setVoucherDetails([...data]);
                                        calculateAmount(index);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    {console.log((row?.quantity*((row?.tax/100)*row?.sale_price)).toFixed(2),"hsfj",row?.tax)}
                                    {console.log((row?.tax && !isNaN(row.tax))?true:false,"fdtieu",row?.tax)}
                                    <input
                                      placeholder=" "
                                      type="text"
                                      id={`narration${index}`}
                                      name={`narration${index}`}
                                      className=" form-control"
                                      value={(row?.tax && !isNaN(row.tax))?(row?.quantity*((row?.tax/100)*row?.sale_price)).toFixed(2):0}
                                      // onChange={(e) => {
                                      //   const { value } = e.target;
                                      //   let data = voucherDetails;
                                      //   data[index].sale_price = value;
                                      //   const qty = data[index].quantity;
                                      //   const pro_amount = parseFloat(
                                      //     value * qty
                                      //   );
                                      //   data[index].amount =
                                      //     pro_amount.toFixed(2);
                                      //   setVoucherDetails([...data]);
                                      //   calculateAmount(index);
                                      // }}
                                      disabled
                                    />
                                  </div>
                                </StyledTableCell>
                                {/* <StyledTableCell align="">
                                  <Select
                                    className="js-states form-control"
                                    options={TaxPercentage}
                                    dropdownHandle={true}
                                    searchable={true}
                                    values={
                                      TaxPercentage?.filter(
                                        (ele) =>
                                          ele.name == voucherDetails[index].tax
                                      ) ?? []
                                    }
                                    labelField={"name"}
                                    valueField={"name"}
                                    name={`vat${index}`}
                                    onChange={(value) => {
                                      let data = [...voucherDetails];
                                      data[index].tax = value[0]?.name;
                                      calculateAmount(index);
                                    }}
                                  />
                                </StyledTableCell> */}
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      className=" form-control"
                                      type="text"
                                      value={row?.amount}
                                      id={`narration${index}`}
                                      name={`narration${index}`}
                                      disabled
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  align=""
                                  className="button-items"
                                >
                                  <a
                                    href="javascript:void(0);"
                                    title="Delete"
                                    className="btn btn-danger delete-btn"
                                    onClick={() => {
                                      if (voucherDetails?.length > 1) {
                                        const voucherDetail = _.filter(
                                          voucherDetails,
                                          (key, i) => i != index
                                        );
                                        console.log(
                                          voucherDetail,
                                          "check voucherDetails"
                                        );
                                        setVoucherDetails(voucherDetail);
                                      }
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{ fontSize: 20, color: "black" }}
                                    />
                                    {/* <i className="ri-delete-bin-line"></i> */}
                                  </a>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div className="invoice-width">
                      <div class="input-container w-100">
                        {/* <textarea
                            id="net_amount"
                            rows={3}
                            name="notes"
                            {...register("notes")}
                          />
                          <label for="notes">Notes</label> */}
                      </div>
                    </div>
                    <div className="invoice-width">
                      <div class="input-container w-100">
                        <label for="total_amount">Sub Total Amount</label>
                        <input
                          className="w-100 fs-5"
                          style={{ border: "none", borderBottom: "1px solid" }}
                          placeholder=" "
                          type="text"
                          id="total_amount"
                          name="total_amount"
                          value={totalAmt}
                          disabled
                        />
                      </div>
                      {/* <div class="input-container w-100">
                          <input
                            placeholder=" "
                            type="text"
                            id="discount"
                            name="discount"
                            value={discount_val}
                            readOnly
                          />
                          <label for="discount">Total Discount Amount</label>
                        </div> */}
                      <div className="input-container w-100 d-none">
                        {/* <Controller
                            name="vat"
                            control={control}
                            render={({ field, ref }) => (
                              <Select
                                className="js-states select-control"
                                options={requiredTaxes}
                                {...field}
                                ref={ref}
                                dropdownHandle={true}
                                searchable={true}
                                values={taxType}
                                labelField={"name"}
                                valueField={"name"}
                                name="vat"
                                onChange={(value) => {
                                  setTaxType([value[0]]);
                                }}
                              />
                            )}
                          /> */}
                        <input type="text" readOnly></input>
                      </div>
                      <div class="input-container w-100">
                        <label for="vat_amount">Tax Amount</label>
                        <input
                          className="w-100 fs-5 "
                          style={{ border: "none", borderBottom: "1px solid" }}
                          placeholder=" "
                          type="text"
                          id="vat_amount"
                          name="vat_amount"
                          disabled
                          value={vat_amount}
                        />
                      </div>
                      <div class="input-container w-100">
                        <label for="tran_amount">Transport Charges</label>
                        <input
                          className="w-100 fs-5 "
                          style={{ border: "none", borderBottom: "1px solid" }}
                          placeholder=" "
                          type="text"
                          id="tran_amount"
                          name="tran_amount"
                          onChange={(e) => {
                            transport(e.target.value);
                          }}
                          value={tran_amount}
                        />
                      </div>

                      {/* <div class="input-container w-100 d-none">
                        <label for="freight_charge">Freight Charges</label>
                          <input
                            placeholder=" "
                            className="w-100"
                            type="text"
                            id="freight_charge"
                            name="freight_charge"
                            value={freightCharge}
                            onChange={(e) =>
                              setfreightCharge(
                                e.target.value != "" ? e.target.value : "0"
                              )
                            }
                          />
                          
                        </div> */}
                      <div class="input-container w-100">
                        <label for="net_amount">Net Amount</label>
                        <input
                          placeholder=" "
                          className="w-100 fs-5"
                          style={{ border: "none", borderBottom: "1px solid" }}
                          type="text"
                          id="net_amount"
                          name="net_amount"
                          disabled
                          value={total}
                          // {...register("net_amount")}
                        />
                      </div>
                      {/* <div class="input-container w-100">
                          <label for="round_off">Round Off</label>
                          <input
                            className="w-100 fs-5"
                            style={{ border: "none", borderBottom: "1px solid" }}
                            placeholder=" "
                            type="text"
                            id="round_off"
                            name="round_off"
                            value={roundOffAmt}
                            onChange={(e) => setroundOffAmt(e.target.value)}
                          />
                        </div> */}
                      {errors?.net_amount?.message && (
                        <p className="error">{errors.net_amount.message}</p>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="text-end">
                <Button
                  color="primary"
                  // onClick={() => console.log("hello")}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  );
};
export default CreateOrder;
