import alertmessage from "../../utils/alert";
import { decryptdata } from "../../utils/encrypt&decrypt";
import { UserActionTypes, userInitialState } from "./user.model";
import _ from "underscore";

const reducer = (state = userInitialState, action) => {
  switch (action.type) {
    case UserActionTypes.UPDATE_TOKEN_REQUEST:
    case UserActionTypes.UPDATE_SETTINGS_REQUEST:
    case UserActionTypes.VERIFY_PIN_REQUEST:
    case UserActionTypes.GET_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UserActionTypes.UPDATE_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UserActionTypes.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.VERIFY_PIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case UserActionTypes.GET_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stats: action.payload,
      };

    case UserActionTypes.FETCH_BALANCE_REQUEST: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case UserActionTypes.FETCH_BALANCE_SUCCESS: {
      let resp = _.uniq(action.response);
      return Object.assign({}, state, {
        isLoading: false,
        coinBalance: resp,
      });
    }
    case UserActionTypes.GET_USERSOCIAL_REQUEST: {
      return {
        ...state,
        userSocialLoader: true,
      };
    }
    case UserActionTypes.GET_USERSOCIAL_SUCCESS:
      var { payload } = action;
      console.log(payload, "get users social success userAuthedSocials");
      return {
        ...state,
        userAuthedSocials: payload?.data?.data,
        userSocialLoader: false,
      };
    case UserActionTypes.GET_USERSOCIAL_FAILURE:
      var { payload } = action;
      console.log(payload, "get user social failure");
      return {
        ...state,
        userSocialLoader: false,
      };

    case UserActionTypes.UPDATE_TOKEN_FAILURE:
    case UserActionTypes.UPDATE_SETTINGS_FAILURE:
    case UserActionTypes.VERIFY_PIN_FAILURE:
    case UserActionTypes.GET_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UserActionTypes.GETUSERDATA_REQUEST:
      return {
        ...state,
        userDataLoading: true,
      };
    case UserActionTypes.GETUSERDATA_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas2 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas2.data, "yhoio");
      return {
        ...state,
        userDataLoading: false,
        userData: payloaddatas2,
      };
    case UserActionTypes.GETUSERDATA_FAILURE:
      console.log(action.payload.response.data.message,'jhfksf34');
      
      if(action.payload.response.data.message==="Your session expired! Please login to continue!"){
        alertmessage.sweetalert(action.payload.response.data.message)
      }
      return {
        ...state,
        userDataLoading: false,
      };

      case UserActionTypes.GETUSERDATABYID_REQUEST:
      return {
        ...state,
        idDataLoading: true,
      };
    case UserActionTypes.GETUSERDATABYID_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas22 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas22.data, "yhoio");
      return {
        ...state,
        idDataLoading: false,
        idData: payloaddatas22,
      };
    case UserActionTypes.GETUSERDATABYID_FAILURE:
      console.log(action.payload.response.data.message,'jhfksf34');
      
      if(action.payload.response.data.message==="Your session expired! Please login to continue!"){
        alertmessage.sweetalert(action.payload.response.data.message)
      }
      return {
        ...state,
        idDataLoading: false,
      };

      case UserActionTypes.GETUSERSCREENBYID_REQUEST:
        console.log('GETUSERSCREENBYID_REQUEST');
        
      return {
        ...state,
        screenDataLoading: true,
      };
    case UserActionTypes.GETUSERSCREENBYID_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "dghkjsk");
      let payloaddatasS = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatasS, "jhsygu");
      return {
        ...state,
    
        screenData:payloaddatasS,
        screenDataLoading:false,
      };
    case UserActionTypes.GETUSERSCREENBYID_FAILURE:
      
      
      if(action.payload.response.data.message==="Your session expired! Please login to continue!"){
        alertmessage.sweetalert(action.payload.response.data.message)
      }
      return {
        ...state,
        screenDataLoading: false,
      };
    case UserActionTypes.CREATEUSER_REQUEST:
      return {
        ...state,
        createUserLoad: true,
        createSuccess: false,
      };
    case UserActionTypes.CREATEUSER_SUCCESS:
      console.log(action,'sghsk');
      
      return {
        ...state,
        createUserLoad: false,
        createSuccess: true,
      };
    case UserActionTypes.CREATEUSER_FAILURE:
      console.log(action.payload.response.data.errors,'sghsk');
      return {
        ...state,
        createUserLoad: false,
        createSuccess: false,
      };
    case UserActionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        updateUserLoad: true,
        updateSuccess: false,
      };
    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoad: false,
        updateSuccess: true,
      };
    case UserActionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserLoad: false,
        updateSuccess: false,
      };
    case UserActionTypes.DELETEUSERDATA_REQUEST:
      return {
        ...state,
        deleteLoad: true,
        deleteLoadSuccess: false,
      };
    case UserActionTypes.DELETEUSERDATA_SUCCESS:
      return {
        ...state,
        deleteLoad: false,
        deleteLoadSuccess: true,
      };
    case UserActionTypes.DELETEUSERDATA_FAILURE:
      return {
        ...state,
        deleteLoad: false,
        deleteLoadSuccess: false,
      };
      
      case UserActionTypes.GET_SCREEN_REQUEST:
      return {
        ...state,
        screenLoading:true,
      };

    case UserActionTypes.GET_SCREEN_SUCCESS:
      console.log(action.payload.data.data,'fsjghksh');
      
      return {
        ...state,
        screenLoading:false,
        screens:action.payload.data.data,
      };

    case UserActionTypes.GET_SCREEN_FAILURE:
      return {
        ...state,
        screenLoading:false,
      };

    case UserActionTypes.USER_LOGOUT:
      return {
        ...state,
        createUserLoad: false,
        createSuccess: false,
        updateUserLoad: false,
        updateSuccess: false,
        userData: [],
        deleteLoad: false,
        deleteLoadSuccess: false,
        screenLoading:false,
        screens:[],
      };
    default:
      return state;
  }
};

export { reducer as UserReducer };
